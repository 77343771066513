import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import './More.scss';
import {GetCommon, GetMore_Slider} from '../../resources/image/Image';
import CPBottomModal from '../../common/components/bottomModal/CPBottomModal';
import CPChangeLang from '../../common/components/bottomModal/CPChangeLang';
import Feedback from '../../common/components/root/feedback/Feedback';
import {useNav as useNavigate} from '../../common/utils/helper';

export default function More() {
  const Common = GetCommon();
  const More_Slider = GetMore_Slider();
  const navigate = useNavigate();
  const {t: trans} = useTranslation();
  const {salesKit, premiumUrl, website} = useSelector(({company}) => company);
  const [langModal, setLangModal] = useState('none');

  var bottom = document.getElementById('slideFromBottom_modal');

  window.onclick = function (event) {
    if (event.target === bottom) {
      setLangModal('none');
    }
  };

  function CloseModal() {
    document.getElementById('more_slider').style.transform = 'translateX(100%)';
    document.getElementById('more_slider').style.display = 'none';
    document.body.style.position = 'relative';
    document.body.style.overflow = 'visible';
    document.body.style.touchAction = 'auto';
  }

  const data = [
    {
      key: 1,
      name: trans('homepage.act.profile'),
      icon: More_Slider.myProfile,
    },
    {
      key: 2,
      name: trans('homepage.act.downline'),
      icon: More_Slider.downline,
    },
    salesKit && {
      key: 3,
      name: trans('homepage.act.learnMore'),
      icon: More_Slider.info,
    },
    website && {
      key: 4,
      name: trans('homepage.act.web'),
      icon: More_Slider.website,
    },
    premiumUrl && {
      key: 5,
      name: trans('homepage.act.premium'),
      icon: More_Slider.premium,
    },
    {
      key: 6,
      name: trans('homepage.act.language'),
      icon: More_Slider.lang,
    },
    {
      key: 7,
      name: trans('homepage.act.feedback'),
      icon: More_Slider.feedback,
    },
  ];

  const goTo = async (key) => {
    switch (key) {
      case 1:
        navigate('/profile');
        break;

      case 2:
        navigate('/downline', {
          state: {
            from: 'more',
          },
        });
        break;

      case 3:
        navigate('/learnMore', {
          replace: true,
          state: {
            back: '/',
          },
        });
        break;

      case 4:
        navigate('/website');
        break;

      case 5:
        navigate('/premium');
        break;

      case 6:
        setLangModal('block');
        break;

      case 7:
        CloseModal();
        Feedback.show();
        break;

      default:
        navigate('/');
    }
  };

  function Render() {
    return data.map((item, index) => {
      const {key, name, icon} = item;
      return item ? (
        <div
          key={index}
          className="more-item-style"
          onClick={() => {
            // if (key === 3) return;
            // document.getElementById('more_slider').style.display = 'none';
            document.body.style.position = 'relative';
            document.body.style.overflow = 'visible';
            document.body.style.touchAction = 'auto';
            goTo(key);
          }}>
          <img src={icon} className="more-icon" alt="more-icon" />
          <div>{name}</div>
        </div>
      ) : null;
    });
  }

  return (
    <div id="more_slider" className="more-slider-container">
      <CPBottomModal
        id="slideFromBottom_modal"
        isVisible={langModal}
        background={'black'}
        callback={() => setLangModal('none')}
        content={
          <CPChangeLang
            fontColor={'more-lang-txt'}
            callback={() => setLangModal('none')}
            containerBg={'more-lang-bg'}
          />
        }
      />
      <div className="more" onClick={() => CloseModal()} />
      <div className="more-slider-content">
        <div className="more-title-container">
          <button className="more-close-btn" onClick={() => CloseModal()}>
            <img
              src={Common.close2}
              className="more-close-icon"
              alt="more-close-icon"
            />
          </button>
          <div className="more-title-txt">{trans('homepage.act.title')}</div>
        </div>
        <div className="more-item-container">
          <Render />
        </div>
      </div>
    </div>
  );
}
