export const api = {
  AppInit: 'app/init',
  AppMarquee: 'app/marquee',
  AppNews: 'app/news',
  AppFeedback: 'app/feedback',

  //#region ===> Sign In
  VerifyMobileNumber: 'user/verifyMobileNumber',
  VerifyPassword: 'user/verifyPassword', //verify change pin using this
  //#endregion

  //#region ===>  Sign Up
  RequestOpt: 'otp/request',
  VerifyOtp: 'otp/verify',
  VerifyReferrer: 'user/verifyReferrer',
  SignUp: 'user/signUp',
  VerifyBank: '/bank/verify',
  //#endregion

  //#region ===> User
  GetUserRules: 'user/getUserRules',
  CheckUserSession: 'user/checkUserSession',
  ResetPassword: 'user/changePassword',
  ForgotPin: 'user/resetPassword',
  UserDownline: 'user/downline',
  VerifyReferCode: 'user/verifyReferCode',
  UpdateReferCode: 'user/updateReferCode',
  Notification: 'user/notification',
  LineLogin: 'user/lineLogin',
  LineUpdate: 'user/lineUpdate',
  GetLineIDInfo: 'user/getLineIDInfo',
  //#endregion

  //#region ===> Game
  GameList: 'game/list',
  PlayGame: 'game/play',
  ExitGame: 'game/exit',
  GetAppGameInfo: '/game/userInfo',
  UpdatePassword: '/game/updatePassword',
  //#endregion

  //#region ===> Club
  SportList: 'sport/list',
  SportWatch: 'sport/watch',
  PornList: 'porn/list',
  MovieList: 'movie/list',
  //#endregion

  //#region ===> Wallet
  Balance: 'user/balance',
  BankList: 'bank/list',
  AddBank: 'bank/add',
  AddBankV2: 'bank/add/v2',
  UserTurnover: 'user/turnover',
  //#endregion

  //#region ===> Topup
  TopupBank: 'topup/bank',
  TopupStart: 'topup/start',
  TopupPending: 'topup/pending',
  TopupRequest: 'topup/request',
  TopupCancel: 'topup/cancel',
  //#endregion

  //#region ===> Withdraw
  Withdraw2Bank: 'withdraw/bank',
  WithdrawComm2Bank: 'withdraw/comm2bank',
  WithdrawComm2Wallet: 'withdraw/comm2wallet',
  //#endregion

  //#region ===> Transaction
  RecordWallet: 'record/wallet',
  RecordGame: 'record/game',
  RecordComm: 'record/comm',
  RecordRebateTotal: 'record/rebateTotal',
  RecordRebateSummary: 'record/rebateSummary',
  RecordRebateDetail: 'record/rebateDetail',
  RecordAffiliateComm: 'record/affiliateComm',
  RecordAffiliateCommSummary: 'record/affiliateCommSummary',
  //#endregion

  //#region ===> Mission
  MissionList: 'mission/list',
  MissionClaim: 'mission/claim',
  //#endregion

  //#region ===> Promo
  PromoCheckFortuneWheel: 'promo/checkFortuneWheel',
  PromoClaimFortuneWheel: 'promo/claimFortuneWheel',
  PromoUnclaimFortuneWheel: 'promo/unclaimFortuneWheel',
  //#endregion

  //#region ===> TrueWallet
  TopUpTrueWallet: 'topup/twallet',
  //#endregion

  //#region ===> Page
  PageSetting: '/app/pageSetting',
  //#endregion
};
