import React, {useEffect, useRef, useState} from 'react';
import './Register.scss';
import {useTranslation} from 'react-i18next';
import {GetTextField} from '../../resources/image/Image';
import FormComponent from '../../common/components/Form/FormComponent';
import LoginCommonTf from '../../common/components/TextField/LoginCommonTf';
import PasswordTextField from '../../common/components/TextField/PasswordTextField';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';
import LoginOrSeparator from '../../common/components/LoginComponent/LoginOrSeparator';
import ThirdPartyLoginComponent from '../../common/components/LoginComponent/ThirdPartyLoginComponent';
import LoginMobileTf from '../../common/components/TextField/LoginMobileTf';
import {clientInfo} from '../../resources';
import {useSelector} from 'react-redux';
import {getReferral, useNav, clearReferral} from '../../common/utils/helper';
import api from '../../common/api';
import {useLocation, useSearchParams} from 'react-router-dom';
import ReferralTextField from '../../common/components/TextField/ReferralTextfield';
import {
  LoginWithLine,
  VERIFYMOBILE_PREV_PAGE_KEY,
} from '../../common/utils/loginHelper';
import {
  MsgModalObject,
  useMsgModal,
} from '../../common/context/MsgModalContext';
import ErrMsgModal from '../../common/components/modal/ErrMsgModal';
import { SignUpAndLogin } from '../../common/utils/loginHelper';

export default function Register({regSkipOtp}) {
  const {pushMsgModal} = useMsgModal();
  const navigate = useNav();
  const {lineClientId, skipBindBank} = useSelector(({company}) => company);
  const mobileCountryCode = 'mCountryCode';
  const mobileTfName = 'mobile';
  const emailTfName = 'email';
  const pwTfName = 'password';
  const confirmPwTfName = 'confirmPassword';
  const refTfName = 'referral';
  const {t: trans} = useTranslation();
  const tfImg = GetTextField();
  const formRef = useRef(null);
  const submitBtnRef = useRef(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [formData, setFormData] = useState({
    [mobileCountryCode]: clientInfo.defaultMobileCode,
  });
  const registerConfig = clientInfo.register;
  // const enableRegisterBank = clientInfo.register.enableRegisterBank;

  const handleFieldChange = (e) => {
    const {name, value} = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = async () => {
    const isValid = await formRef.current.ValidateValue();
    let finalMobile = formData[mobileTfName];
    if (isValid) {
      setIsVerifying(true);
      api
        .VerifyMobileNumber({
          mobileNumber: finalMobile,
          success: (res) => {
            if (!res.data.userId) {
              if (!res.data.regSkipOtp) {
                navigate(
                  `/${VERIFYMOBILE_PREV_PAGE_KEY.register}/verifyMobile`,
                  {
                    state: {
                      mobile: finalMobile,
                      userData: formData,
                    },
                  },
                );
              } else if (!skipBindBank) {
                navigate('/registerBank', {
                  state: {
                    userData: formData,
                  },
                });
              } else {
                const mobile = formData[mobileTfName];
                const referral = formData[refTfName];
                const registerPw = formData[pwTfName];

                SignUpAndLogin(navigate, mobile, referral, registerPw);
              }
            } else {
              pushMsgModal(
                new MsgModalObject({
                  content: <ErrMsgModal title={'err.MOBILE_REGISTERED'} />,
                }),
              );
            }
          },
        })
        .finally(() => {
          setIsVerifying(false);
        });
    }
  };

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  return (
    <div className="register-container" style={(regSkipOtp && skipBindBank) ? {paddingTop: '53px'} : {}}>
      <FormComponent
        ref={formRef}
        submitBtnRef={submitBtnRef}
        className="register-form-container">
        {registerConfig.mobile && (
          <LoginMobileTf
            isFormChild={true}
            name={mobileTfName}
            placeholder={trans('general.label.mobileNo')}
            value={formData[mobileTfName] || ''}
            countryCode={formData[mobileCountryCode] || ''}
            isRequired={true}
            onChange={handleFieldChange}
            countryCodeOnChange={(value) => {
              updateFormData(mobileCountryCode, value);
            }}
          />
        )}
        {registerConfig.email && (
          <LoginCommonTf
            isFormChild={true}
            icon={tfImg.email}
            name={emailTfName}
            placeholder={trans('general.placeholder.email')}
            value={formData[emailTfName] || ''}
            onChange={handleFieldChange}
            isRequired={true}
            validation={(value) => {
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

              if (emailRegex.test(value)) {
                return '';
              } else {
                return 'general.errMsg.emailFormat';
              }
            }}
          />
        )}

        {registerConfig.password && (
          <PasswordTextField
            isFormChild={true}
            name={pwTfName}
            placeholder={trans('general.placeholder.password')}
            value={formData[pwTfName] || ''}
            onChange={(e) => {
              const confirmTf =
                formRef.current.getTextFieldRefByName(confirmPwTfName)?.current;
              if (confirmTf && confirmTf.isRealTimeValidation) {
                const confirmPwValue = formData[confirmPwTfName] ?? '';
                const pwValue = e.value;
                if (confirmPwValue != '') {
                  if (confirmPwValue != pwValue) {
                    confirmTf.setErrMsg('general.errMsg.pwNotMatch');
                  } else {
                    confirmTf.setErrMsg('');
                  }
                }
              }
              handleFieldChange(e);
            }}
            isRequired={true}
            enableHideShow={true}
          />
        )}
        {registerConfig.confirmPw && (
          <PasswordTextField
            isFormChild={true}
            name={confirmPwTfName}
            placeholder={trans('forgotPass.label.confirmPass')}
            value={formData[confirmPwTfName] || ''}
            onChange={handleFieldChange}
            isRequired={true}
            enableHideShow={true}
            validation={(value) => {
              if (value != formData[pwTfName] ?? '') {
                return 'general.errMsg.pwNotMatch';
              } else {
                return '';
              }
            }}
          />
        )}

        <ReferralTextField name={refTfName} onChange={handleFieldChange} />
      </FormComponent>
      <div className="register-btn-section-container">
        <ButtonComponent_v2
          ref={submitBtnRef}
          text={trans('signIn.register')}
          btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
          btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
          disable={isVerifying}
          onClick={() => {
            handleButtonClick();
          }}
        />
        <LoginOrSeparator />
        <ThirdPartyLoginComponent
          onClick={(value) => {
            switch (value) {
              case 'line':
                LoginWithLine(lineClientId);
                break;

              default:
                break;
            }
          }}
        />
      </div>
    </div>
  );
}
