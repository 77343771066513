import {createSlice} from '@reduxjs/toolkit';

//example data
// "accounts": [
//     {
//         "user": "th492729287",
//         "password": "123123",
//         "gameGroup": "PUSSY"
//     },
//     {
//         "user": "th319740315-1",
//         "password": "123123",
//         "gameGroup": "PUSSY-bak"
//     },
//     {
//         "user": "th319740315",
//         "password": "123123",
//         "gameGroup": "PUSSY2"
//     }
// ],
const initialState = {
  accounts: [],
  selectedAccount: {},
  iosDownloadLink: '',
  androidDownloadLink: '',
};

export const appGameSlice = createSlice({
  name: 'appGame',
  initialState,
  reducers: {
    setAppGameInfo: (state, action) => {
      state.accounts = action.payload.accounts;
      if (action.payload.accounts && action.payload.accounts.length > 0) {
        state.selectedAccount = action.payload.accounts[0];
      }
      state.iosDownloadLink = action.payload.iosDownloadLink;
      state.androidDownloadLink = action.payload.androidDownloadLink;
    },
    setSelectedAccount: (state, action) => {
      const selectedAcc = state.accounts.find(
        (value) => value.user == action.payload.user,
      );
      if (selectedAcc) state.selectedAccount = selectedAcc;
    },
    updateAccountPw: (state, action) => {
      const newPass = action.payload.password;
      if (newPass) {
        state.selectedAccount.password = newPass;
        const selectedAcc = state.accounts.find(
          (value) => value.user == action.payload.selectedAccount.user,
        );
        const dashboardInfoString = sessionStorage.getItem('dashboardInfo');
        let dashboard;
        if (dashboardInfoString) {
          dashboard = JSON.parse(dashboardInfoString);
        }
        if (selectedAcc) {
          selectedAcc.password = newPass;
          dashboard.selectedProvider.accounts = state.accounts;
        }    
        sessionStorage.setItem('dashboardInfo', JSON.stringify(dashboard))
      }
    },
  },
});

export const {setAppGameInfo, setSelectedAccount, updateAccountPw} =
  appGameSlice.actions;
export default appGameSlice.reducer;
