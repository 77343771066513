import React, {useEffect, useRef, useState} from 'react';
import './ChangePassword.scss';
import {useTranslation} from 'react-i18next';
import FormComponent from '../../common/components/Form/FormComponent';
import PasswordTextField from '../../common/components/TextField/PasswordTextField';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';
import {clientInfo} from '../../resources';
import api from '../../common/api';
import {useLocation} from 'react-router-dom';
import {useNav} from '../../common/utils/helper';
import {useSelector} from 'react-redux';
import {
  MsgModalObject,
  useMsgModal,
} from '../../common/context/MsgModalContext';
import ErrMsgModal from '../../common/components/modal/ErrMsgModal';
import BackNav from '../../common/components/navigationBar/BackNav';
import BackButton from '../../common/components/navigationItem/BackButton';

export default function ChangePassword() {
  const {pushMsgModal} = useMsgModal();
  const location = useLocation();
  const navigate = useNav();
  const {mobile} = location.state || {};
  const oldPwTfName = 'oldPassword';
  const pwTfName = 'password';
  const confirmPwTfName = 'confirmPassword';
  const {t: trans} = useTranslation();
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const submitBtnRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [isInProgress, setIsInProgress] = useState(false);
  const registerConfig = clientInfo.register;
  const {profile} = useSelector(({user}) => user);
  const userMobile = profile?.mobileNumber?.toString() || '';

  const handleFieldChange = (e) => {
    const {name, value} = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = async () => {
    const isValid = await formRef.current.ValidateValue({});
    console.log(formData);
    if (isValid) {
      const oldPassword = formData[oldPwTfName];
      const password = formData[pwTfName];
      verifyPassword(oldPassword, () => {
        changePassword(password, oldPassword);
      });
    }
  };

  const verifyPassword = (password, success) => {
    setIsInProgress(true);
    api.VerifyPassword({
      showSpinner: false,
      showErrDialog: false,
      userId: userMobile,
      password: password,
      success: success,
      error: (err) => {
        const {errcode, msg} = err || {};
        if (errcode == '102') {
          const oldPwTf =
            formRef.current.getTextFieldRefByName(oldPwTfName)?.current;
          if (oldPwTf) {
            oldPwTf.setErrMsg('dialog.err.wrongPass', true);
          }
        } else {
          pushMsgModal(
            new MsgModalObject({
              content: (
                <ErrMsgModal
                  title={errcode ? `${errcode}: ${msg}` : 'dialog.err.someErr'}
                />
              ),
            }),
          );
        }
        setIsInProgress(false);
      },
    });
  };

  const changePassword = (password, oldPassword) => {
    api
      .ResetPassword({
        showSpinner: false,
        mobileNumber: userMobile,
        password: password,
        oldPassword: oldPassword,
        success: () => {
          pushMsgModal(
            new MsgModalObject({
              content: (
                <ErrMsgModal
                  title={'dialog.msg.changePass'}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              ),
            }),
          );
        },
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  return (
    <div className="changePassword-container container-padding">
      <div ref={containerRef} className="changePassword-style">
        <div className="changePassword-sticky-header">
          <BackNav
            middleComponent={
              <div className="changePassword-title">
                {trans('general.title.changePass')}
              </div>
            }
            rightComponent={
              <BackButton style={{visibility: 'hidden'}} />
            }></BackNav>
        </div>
        <FormComponent
          submitBtnRef={submitBtnRef}
          ref={formRef}
          frameRef={containerRef}
          className="changePassword-form-container">
          <PasswordTextField
            isFormChild={true}
            name={oldPwTfName}
            placeholder={trans('general.placeholder.currentPw')}
            value={formData[oldPwTfName] || ''}
            isRequired={true}
            enableHideShow={true}
            formatValidation={false}
            onChange={(e) => {
              handleFieldChange(e);
            }}
          />
          {registerConfig.password && (
            <PasswordTextField
              isFormChild={true}
              name={pwTfName}
              placeholder={trans('general.placeholder.password')}
              value={formData[pwTfName] || ''}
              isRequired={true}
              enableHideShow={true}
              onChange={(e) => {
                const confirmTf =
                  formRef.current.getTextFieldRefByName(
                    confirmPwTfName,
                  )?.current;
                if (confirmTf && confirmTf.isRealTimeValidation) {
                  const confirmPwValue = formData[confirmPwTfName] ?? '';
                  const pwValue = e.value;
                  if (confirmPwValue != '') {
                    if (confirmPwValue != pwValue) {
                      confirmTf.setErrMsg('general.errMsg.pwNotMatch');
                    } else {
                      confirmTf.setErrMsg('');
                    }
                  }
                }
                handleFieldChange(e);
              }}
            />
          )}

          {registerConfig.confirmPw && (
            <PasswordTextField
              isFormChild={true}
              name={confirmPwTfName}
              placeholder={trans('forgotPass.label.confirmPass')}
              value={formData[confirmPwTfName] || ''}
              onChange={handleFieldChange}
              isRequired={true}
              enableHideShow={true}
              validation={(value) => {
                if (value != formData[pwTfName] ?? '') {
                  return 'general.errMsg.pwNotMatch';
                } else {
                  return '';
                }
              }}
            />
          )}
        </FormComponent>
        <div className="changePassword-btn-section-container">
          <ButtonComponent_v2
            ref={submitBtnRef}
            text={trans('general.btn.confirm')}
            btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
            btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
            disableBtnClassName={
              'btnV2-container-row-center-p17-r10-mainshadow-disabled'
            }
            disableBtnTextClassName={'btnV2-btn-txt-s20-w700-cMain-disabled'}
            disable={isInProgress}
            onClick={() => {
              handleButtonClick();
            }}
          />
        </div>
      </div>
    </div>
  );
}
