import Home from '../home/Home';
import Intro from '../Intro/Intro';
import Club from '../club/Club';
import VerifyMobile from '../sign_in_out/VerifyMobile';
import Chat from '../chat/Chat';
import Mission from '../mission/Mission';
import {t as trans} from 'i18next';

const PublicRoutes = [
  // {
  //   path: '/game',
  //   element: <Home />,
  // },
  {
    path: '/',
    element: <Intro />,
  },
  {
    path: '/club',
    element: <Club title={'TKClub'} />,
  },
  {
    path: '/:section/verifyMobile',
    element: <VerifyMobile />,
  },
  {
    path: '/chat',
    element: <Chat title={trans('general.title.chat')} />,
    state: { tabSelected: 'LINE' }
  },
  {
    path: '/mission',
    element: <Mission title={trans('general.title.mission')} />,
  },
];

export default PublicRoutes;
