import React, {useEffect, useState} from 'react';
import './SideMenu.scss';
import {useSelector, useDispatch} from 'react-redux';
import {openSideMenu} from '../../common/redux/sideMenuSlice';
import SideMenuHomeButton from '../../common/components/button/SideMenuHomeButton';
import {GetCommon, GetSideMenu, GetWallet} from '../../resources/image/Image';
import NavBrandLogo from '../../common/components/NavBrandLogo/NavBrandLogo';
import LanguageComponent from '../../common/components/language/LanguageComponent';
import i18n from '../../i18n';
import MarqueeComponent from '../home/Marquee';
import SideMenuItem from '../../common/components/sideMenuItem/SideMenuItem';
import {useTranslation} from 'react-i18next';
import {useNav} from '../../common/utils/helper';
import Feedback from '../../common/components/root/feedback/Feedback';
import {logOut} from '../../common/redux/userSlice';
import api from '../../common/api';
import FundCardComponent from '../../common/components/fundCard/FundCardComponent';

export default function SideMenu() {
  const {t: trans} = useTranslation();
  const Common = GetCommon();
  const SideMenu = GetSideMenu();
  const dispatch = useDispatch();
  const {sideMenuIsOpen} = useSelector((state) => state.sideMenu);
  const {CMCategoryGame} = useSelector((state) => state.category);
  const {isLogin} = useSelector((state) => state.user);
  const navigate = useNav();
  const Wallet = GetWallet();

  useEffect(() => {
    if (sideMenuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [sideMenuIsOpen]);

  function closeSideMenu(callback) {
    dispatch(openSideMenu(false));
    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  function navigateToGame(category) {
    let categoryObject = CMCategoryGame.find(
      (item) => item.categoryen === category,
    );
    console.log(categoryObject);
    navigate('/', {state: {selectedCategory: categoryObject}});
  }

  return (
    <div
      className={`side-menu-dimmed-container ${
        sideMenuIsOpen && 'side-menu-dimmed-container-open'
      }`}
      onClick={() => {
        closeSideMenu();
      }}>
      <div
        className={`side-menu-container ${
          sideMenuIsOpen && 'side-menu-container-open'
        }`}
        onClick={(e) => {
          e.stopPropagation(); // Prevent click event from bubbling up
        }}>
        <div className="side-menu-nav-container">
          <div className="side-menu-nav-horizontal-container">
            <div className="side-menu-left-section-container">
              <img
                src={Common.sideMenuClose}
                alt="side-menu-close"
                className="side-menu-close"
                onClick={() => {
                  closeSideMenu();
                }}
              />
              <NavBrandLogo textClassName="side-menu-brand-txt" />
            </div>

            <div className="side-menu-right-section-container">
              {/* <SideMenuHomeButton
                onClick={() => {
                  closeSideMenu(() => {
                    navigate('/');
                  });
                }}
              /> */}
              <LanguageComponent
                containerClassName="side-menu-language-container"
                onClick={() => {
                  closeSideMenu();
                }}
              />
            </div>
          </div>
          {/* <MarqueeComponent darkTheme={true} /> */}
        </div>

        {/* Side Menu Content */}

        <div className="side-menu-content-container">
          {isLogin ? (
            <FundCardComponent
              containerClassName={'side-menu-wallet-container'}
              btnTopupClassName={'side-menu-topup-btn'}
              btnWithdrawClassName={'side-menu-withdraw-btn'}
              nameClassName={'side-menu-wallet-name'}
              balTitleClassName={'side-menu-wallet-bal-title'}
              balAmountClassName={'side-menu-wallet-bal-amount'}
              withdrawBalClassName={'side-menu-wallet-withdraw-bal'}
              iconImg={Wallet.moneyIcon3}
              topupCallback={() => {
                closeSideMenu();
              }}
              withdrawCallback={() => {
                closeSideMenu();
              }}
            />
          ) : // <div className="side-menu-wallet-action-container">
          //   <div
          //     className="side-menu-topup"
          //     onClick={() => {
          //       closeSideMenu(() => {
          //         navigate('/autoDeposit');
          //       });
          //     }}>
          //     {trans('general.list.typeTopUp')}
          //   </div>
          //   <div
          //     className="side-menu-withdraw"
          //     onClick={() => {
          //       closeSideMenu(() => {
          //         api.UserBankList({
          //           navigate: () => {
          //             navigate('/withdraw');
          //           },
          //         });
          //       });
          //     }}>
          //     {trans('general.list.typeWith')}
          //   </div>
          // </div>
          null}
          <div className="side-menu-item-group">
            <SideMenuItem
              imgSrc={SideMenu.notification}
              title={trans('sideMenu.notification')}
              onClick={() => {
                closeSideMenu(() => {
                  navigate('/notification');
                });
              }}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.profile}
              title={trans('sideMenu.myProfile')}
              onClick={() => {
                closeSideMenu(() => {
                  navigate('/profile', {state: {hideBackNav: true}});
                });
              }}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.bank}
              title={trans('sideMenu.myBank')}
              onClick={() => {
                closeSideMenu(() => {
                  navigate('/bankList', {state: {hideBackNav: true}});
                });
              }}></SideMenuItem>
            {/* <SideMenuItem
              imgSrc={SideMenu.statement}
              title={trans('sideMenu.statement')}
              onClick={() => {
                closeSideMenu(() => {
                  navigate('/statement', {state: {hideBackNav: true}});
                });
              }}></SideMenuItem> */}
          </div>
          <div className="side-menu-item-group">
            <SideMenuItem
              imgSrc={SideMenu.slot}
              title={trans('sideMenu.slot')}
              onClick={() => {
                closeSideMenu(() => {
                  navigateToGame('Slot');
                });
              }}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.sport}
              title={trans('sideMenu.sport')}
              onClick={() => {
                closeSideMenu(() => {
                  navigateToGame('Sports');
                });
              }}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.live}
              title={trans('sideMenu.live')}
              onClick={() => {
                closeSideMenu(() => {
                  navigateToGame('Live');
                });
              }}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.fish}
              title={trans('sideMenu.fish')}
              onClick={() => {
                closeSideMenu(() => {
                  navigateToGame('Fish');
                });
              }}></SideMenuItem>
            {/* <SideMenuItem
              imgSrc={SideMenu.gamesConfig}
              title={trans('sideMenu.gamesConfig')}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.gamesLog}
              title={trans('sideMenu.gamesLog')}></SideMenuItem> */}
          </div>
          <div className="side-menu-item-group">
            <SideMenuItem
              imgSrc={SideMenu.reward}
              title={trans('sideMenu.promotionAndReward')}
              onClick={() => {
                closeSideMenu(() => {
                  navigate('/mission');
                });
              }}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.vip}
              title={trans('sideMenu.vip')}
              onClick={() => {
                closeSideMenu(() => {
                  navigate('/club');
                });
              }}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.affiliate}
              title={trans('sideMenu.affiliate')}
              onClick={() => {
                closeSideMenu(() => {
                  navigate('/affiliate');
                });
              }}></SideMenuItem>
          </div>

          <div className="side-menu-item-group">
            <SideMenuItem
              imgSrc={SideMenu.contactUs}
              title={trans('sideMenu.contactUs')}
              onClick={() => {
                closeSideMenu(() => {
                  navigate('/chat', {state: {tabSelected: 'LINE'}});
                });
              }}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.feedback}
              title={trans('sideMenu.feedback')}
              onClick={() => {
                closeSideMenu(() => {
                  Feedback.show();
                });
              }}></SideMenuItem>
            {/* <SideMenuItem
              imgSrc={SideMenu.theme}
              title={trans('sideMenu.theme')}></SideMenuItem>
            <SideMenuItem
              imgSrc={SideMenu.downloadApp}
              title={trans('sideMenu.downloadApp')}></SideMenuItem> */}
          </div>
          <div className="side-menu-item-group">
            {isLogin ? (
              <SideMenuItem
                imgSrc={SideMenu.logout}
                title={trans('sideMenu.logout')}
                onClick={() => {
                  closeSideMenu(() => {
                    dispatch(logOut());
                    navigate('/');
                  });
                }}></SideMenuItem>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
