import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  login: {},
  profile: {},
  wallet: {},
  bank: {},
  isMobile: false,
  token: '',
  wdrlBal: 0,
  isLogin: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logIn: (state, action) => {
      state.login = action.payload;
      state.profile = action.payload.profile;
      state.wallet = action.payload.wallet;
      state.bank = action.payload.bank;
      state.isLogin = true;
      sessionStorage.setItem('logIn', JSON.stringify(action.payload));
    },
    balance: (state, action) => {
      state.wallet.currbal = action.payload.currbal;
      state.wallet.comm = action.payload.comm;
      state.wallet.acctname = action.payload.acctname;
    },
    bank: (state, action) => {
      state.wallet.bank = action.payload.bank;
    },
    wdrlBal: (state, action) => {
      state.wdrlBal = action.payload; //internal
    },
    isMobile: (state, action) => {
      state.isMobile = action.payload; //internal
    },
    token: (state, action) => {
      state.token = action.payload;
      sessionStorage.setItem('token', JSON.stringify(action.payload));
    },
    logOut: (state, action) => {
      sessionStorage.clear();
      localStorage.removeItem('verified');
      return initialState;
    },
  },
});

export const {logIn, logOut, isMobile, balance, bank, token, wdrlBal} =
  userSlice.actions;
export default userSlice.reducer;
