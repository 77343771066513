import React from 'react';
import {useTranslation} from 'react-i18next';
import i18n from '../../../i18n';
import {clientInfo} from '../../../resources/index';
import {GetSign_In} from '../../../resources/image/Image';
import './CPChangeLang.scss';
import '../../../pages/sign_in_out/SignIn.scss';

export default function CPChangeLang({callback, fontColor, containerBg}) {
  const {t: trans} = useTranslation();
  const Sign_In = GetSign_In();

  return (
    <div className={`change-lang-container ${containerBg}`}>
      <div className={`change-lang-title ${fontColor}`}>
        {trans('general.label.chooseCountry')}
      </div>

      <div className="change-lang-select-container">
        {clientInfo.optionLang.map((item) => {
          let icon;

          switch (item.key) {
            case 'th':
              icon = Sign_In.country.TH;
              break;

            case 'en':
              icon = Sign_In.country.UK;
              break;

            default:
          }

          return (
            <div
              onClick={() => {
                console.log(`is here click backgrounf`);
                i18n.changeLanguage(item.key);

                callback();
              }}
              key={item.key}
              className={'change-lang-label'}
              style={{
                background: i18n.language === item.key ? null : 'transparent',
              }}>
              <img
                className="change-lang-icon"
                alt={`${item.key}-change-lang-icon`}
                src={icon}
              />
              <div
                className={`change-lang-item-txt ${fontColor} ${
                  i18n.language === item.key && 'change-lang-item-txt-selected'
                }`}>
                {item.lang}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
