import {clientInfo} from '..';

export default {
  general: {
    title: {
      welcome: 'ยินดีต้อนรับ',
      welcome2: 'ลงชื่อ / เข้าสู่ระบบ',
      welcomeTo: 'ยินดีต้อนรับสู่',
      signUp: 'ลงชื่อ',
      register: 'ลงทะเบียน',
      verifyOtp: 'ยืนยันรหัส OTP',
      topUp: 'ฝากเงินแบบทศนิยม',
      bankDetail: 'รายละเอียดธนาคาร',
      withdraw: 'ถอนเงิน',
      transfer: 'โอนเครดิต',
      bankList: 'รายชื่อธนาคาร',
      trxnList: 'รายการธุรกรรม',
      trxnDetail: 'รายละเอียด',
      gameRec: 'บันทึกเกม',
      gameComm: 'คอมมิชชั่นเกม',
      affiliate: 'พันธมิตร',
      notification: 'รายการแจ้งเตือน',
      profile: 'โปรไฟล์ของฉัน',
      cs: 'ฝ่ายบริการลูกค้า',
      newBankAcc: 'บัญชีธนาคารใหม่',
      enterPin: 'กรอกรหัส Passcode',
      newPin: 'รหัส PIN ใหม่',
      confirmPin: 'ยืนยันรหัส PIN ใหม่',
      forgotPin: 'ตั้งรหัส PIN ใหม่',
      forgotPass: 'ตั้งรหัสผ่านใหม่',
      fund: 'การโอนเงิน',
      mission: 'ภารกิจ',
      downline: 'ดาวน์ไลน์',
      comm: 'ค่าคอมมิชชั่น',
      learnMore: 'เรียนรู้เพิ่มเติม',
      web: 'เว็บไซต์',
      premium: 'มาเป็นตัวแทนพรีเมี่ยม',
      movie: 'หนัง',
      sportTV: 'กีฬาทีวี',
      sportStreaming: 'กีฬาทีวี',
      liveScore: 'คะแนนสด',
      UEFA: ' ทำนายผลแชมเปี้ยนส์ลีก',
      porn: 'โป๊',
      chat: 'แชท',
      avatar: 'Avatar',
      commSummary: 'สรุปผลคอมมิชชั่น',
      deposit: 'เงินฝาก',
      autoDeposit: 'เงินฝากอัตโนมัติ',
      changePass: 'เปลี่ยนรหัสผ่าน',
    },

    btn: {
      help: 'ต้องการความช่วยเหลือ?',
      verify: 'ยืนยัน',
      reSend: 'ส่งใหม่อีกครั้ง',
      tryAgain: 'ลองใหม่อีกครั้ง',
      login: 'เข้าสู่ระบบ',
      signOut: 'ออกจากระบบ',
      forgotPin: 'ลืมรหัส PIN?',
      needHelp: 'ต้องการความช่วยเหลือ?',
      next: 'ถัดไป',
      ok: 'ตกลง',
      cancel: 'ยกเลิก',
      delete: 'ลบ',
      submit: 'ยืนยัน',
      add: 'เพิ่ม',
      play: 'เล่น',
      checkStatus: 'ตรวจสอบสถานะ',
      tempPin: 'รับรหัส PIN ชั่วคราว',
      changePass: 'เปลี่ยนรหัสผ่าน',
      changePin: 'เปลี่ยนรหัส PIN',
      back: 'ย้อนกลับ',
      save: 'บันทึก',
      confirm: 'ยืนยัน',
      withdraw: 'ถอนเงิน',
      topup: 'เติมเงิน',
      transBtn: 'โอน',
      inviteBtn: 'เชิญเลย',
      gotIt: 'รับทราบ',
      openBank: 'เปิดแอปธนาคาร',
      home: 'หน้าหลัก',
      reloadBtn: 'กดที่นี่เพื่อกรอวิดีโอ',
      closeAll: 'ปิดทั้งหมด',
      downloadApp: 'ดาวน์โหลดแอป',
      update: 'อัปเดต',
    },

    label: {
      fromBank: 'จากบัญชี',
      transferFrom: 'โอนจาก',
      toBank: 'ไปยังบัญชี',
      refCode: 'รหัสแนะนำ',
      verifyCode: 'รหัสยืนยัน',
      amt: 'กรอกจำนวนเงิน',
      minAmt: ' ฝากขั้นต่ำ 10 บาท',
      accName: 'ชื่อบัญชี',
      bankAccName: 'ชื่อบัญชีธนาคาร',
      bank: 'ธนาคาร',
      accNo: 'เลขที่บัญชี',
      totalBal: 'ยอดเงินคงเหลือ',
      totalComm: 'ค่าคอมมิชชั่น',
      detail: 'รายละเอียด',
      gameInfo: 'ข้อมูลเกม',
      mobileNo: 'เบอร์โทรศัพท์',
      status: 'สถานะ',
      dateTime: 'วันที่ / เวลา',
      to: 'ถึง',
      from: 'จาก',
      type: 'ประเภท',
      commAmt: 'ยอดคอมมิชชั่น',
      chooseCountry: 'เลือกประเทศของคุณ',
      chooseLanguage: 'เลือกภาษา',
      backToTop: 'สูงสุด',
      currentPin: 'รหัส PIN ล่าสุดของคุณ',
      newPin: 'รหัส PIN ใหม่',
      confirmPin: 'ยืนยันรหัส PIN ใหม่',
      totalRebate: 'จำนวนเงินคืนทั้งหมด:',
      totalRebateEarn: 'เงินคืนตลอดชีพ:',
    },

    placeholder: {
      selectBank: 'เลือกธนาคาร',
      entPrefAmt: 'กรุณากรอกจำนวนเงินที่คุณต้องการ',
      enterAcctNo: 'ป้อนหมายเลขบัญชีของคุณ',
      enterAcctName: 'ป้อนชื่อบัญชี',
      enterAmt: 'กรุณาระบุจำนวนเงิน',
      selectType: 'เลือกประเภท',
      mobileNumber: 'เบอร์มือถือ',
      password: 'รหัสผ่าน',
      searching: 'กำลังค้นหา',
      mobileOrPin: 'รหัสผ่าน / PIN 6 หลัก',
      email: 'อีเมล',
      ngad: 'NGAD_SEO',
      enterMobile: 'กรอกเบอร์โทรศัพท์',
      enterName: 'กรอกชื่อของคุณที่นี่',
      autoName: 'ชื่อ (อัตโนมัติ)',
      currentPw: 'รหัสผ่านปัจจุบัน',
      trueAccName: 'กรอกชื่อบัญชี True ของคุณ',
      trueAccNo: 'กรอกหมายเลขบัญชี True ของคุณ',
      confirmTrueAccNo: 'ยืนยันหมายเลขบัญชี True ของคุณ',
      selectTrueAcc: 'เลือกบัญชีของคุณ',
      pleaseSelect: 'กรุณาเลือก',
      googleAuth: 'Google Authenticator',
      enterNewPw: 'ป้อนรหัสผ่านใหม่ของคุณ',
      confirmNewPw: 'ยืนยันรหัสผ่านใหม่ของคุณ'
    },

    dropdown: {
      walletToBank: 'กระเป๋าสตางค์ -> ธนาคาร',
      commToWallet: 'ค่าคอมมิชชั่น -> กระเป๋าสตางค์',
      commToBank: 'ค่าคอมมิชชั่น -> ธนาคาร',
    },

    pin: {
      enterPin: 'กรอกรหัส PIN',
      setPin: 'ตั้งรหัส PIN',
      newPin: 'รหัส PIN ใหม่',
      confirmPin: 'ยืนยันรหัส PIN',
      tempPin: 'รหัส PIN ชั่วคราว',
    },

    list: {
      noRecord: 'ไม่พบข้อมูล',
      noNotif: 'ไม่มีรายการแจ้งเตือน',
      pull: 'เลื่อนเพื่อรีเฟรช',
      filterTitle: 'ประวัติธุรกรรมสูงสุด 30 วันเท่านั้น',
      filterBy: 'กรองตาม',
      filterGame: 'คัดกรองชื่อเกม',
      today: 'วันนี้',
      yesterday: 'เมื่อวาน',
      last7: '7 วัน ล่าสุด',
      last15: '15 วัน ล่าสุด',
      last30: '30 วัน ล่าสุด',
      typeTopUp: 'เติมเงิน',
      typeTrans: 'โอนเงิน',
      typeWith: 'ถอนเงิน',
      typeGame: 'เกม',
      success: 'สำเร็จ',
      reject: 'ยกเลิก',
      pending: 'รอดำเนินการ',
      complete: 'เสร็จสมบูรณ์',
      approved: 'ได้รับการอนุมัติ',
      processing: 'การประมวลผล',
      win: 'ชนะ',
      lose: 'แพ้',
      draw: 'วาด',
      choosePeriod: 'เลือกช่วงเวลา',
      thisWeek: 'สัปดาห์นี้',
      thisMonth: 'เดือนนี้',
      lastMonth: 'เดือนที่แล้ว',
    },

    misc: {
      update: 'กำลังอัปเดต...',
      update2: 'กำลังเริ่มต้น...',
      appNotLatest:
        'แอปไม่ใช่เวอร์ชั่นล่าสุด โปรดอัปเดตเพื่อดำเนินการจากลิงก์ดาวน์โหลด iOS',
      appVer: 'รุ่น :',
      scan: 'สแกน',
      nativeUpdate:
        'เวอร์ชั่นใหม่\nโปรดติดตั้งแอปใหม่อีกครั้งเพื่อรับเวอร์ชั่นล่าสุด\nขั้นตอนที่ 1: ลบแอปล่าสุด\nขั้นตอนที่ 2: คลิกปุ่ม "ติดตั้ง"',
    },

    months: {
      jan: 'ม.ค.',
      feb: 'ก.พ.',
      mar: 'มี.ค.',
      apr: 'เม.ย.',
      may: 'พ.ค.',
      jun: 'มิ.ย.',
      jul: 'ก.ค.',
      aug: 'ส.ค.',
      sep: 'ก.ย.',
      oct: 'ต.ค.',
      nov: 'พ.ย.',
      dec: 'ธ.ค.',
    },

    errMsg: {
      fieldRequired: 'ข้อมูลจำเป็น',
      pwNotMatch: `รหัสผ่านไม่ตรงกัน`,
      phoneFormat: `เบอร์โทรศัพท์ไม่ถูกต้อง`,
      pwFormat: `กรุณากรอกตัวอักษรอย่างน้อย 6 ตัว`,
      emailFormat: `อีเมลไม่ถูกต้อง`,
      countryCode: 'กรุณาเลือกรหัสประเทศ',
      invalidAccNum: 'หมายเลขบัญชีไม่ถูกต้อง',
      bankExists: 'บัญชีธนาคารมีผู้ใช้งานแล้ว กรุณาใช้บัญอื่น',
      accNumNotMatch: `หมายเลขบัญชีไม่ตรงกัน`,
      trueAmtRange: 'กรุณาตรวจสอบให้แน่ใจว่าจำนวนเงินอยู่ในช่วง 10 - 5,000 บาท',
      pwThreeSameCharac: 'รหัสผ่านไม่สามารถใช้ตัวอักษรเดียวกันติดกันสามตัวได้',
      pwUpperCase: 'รหัสผ่านต้องมีอักษรตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว',
      pwLowerCase: 'รหัสผ่านต้องมีตัวอักษรพิมพ์เล็กอย่างน้อยหนึ่งตัว',
      pwNumFormat: 'รหัสผ่านต้องมีตัวเลขอย่างน้อยหนึ่งตัว',
    },
  },

  dialog: {
    btn: {
      ok: 'ตกลง',
      cancel: 'ยกเลิก',
      exit: 'ออก',
      retry: 'ลองใหม่อีกครั้ง',
      yes: 'ตกลง',
      no: 'ไม่',
      done: 'เสร็จแล้ว',
      edit: 'แก้ไข',
      update: 'อัปเดต',
      missionComp: 'เสร็จสมบูรณ์',
      install: 'ติดตั้ง',
      play: 'เล่น',
    },

    msg: {
      updateLatest: 'โปรดอัปเดตเวอร์ชั่นล่าสุด',
      appExit: 'คุณแน่ใจหรือว่าต้องการออกจากแอป?',
      topUpOk: `การเติมเงินจะถูกดำเนินการเมื่อจำนวนเงินเข้าสู่ระบบบ 
  \nระบบจะทำการแจ้งเตือนท่านเมื่อการดำเนินการเสร็จสิ้น`,
      topUpAsk: 'คุณแน่ใจหรือว่าต้องการยกเลิกการเติมเงิน?',
      topUpCancel: 'ยกเลิกการเติมเงินสำเร็จ',
      topUpSuccess: 'เติมเงินเรียบร้อย',
      withdraw: `ยืนยันถอนเงินสำเร็จ\n
  กระบวนการนี้อาจใช้ระยะเวลาดำเนินการสูงสุด 15 นาที`,
      gameExit: 'คุณแน่ใจหรือว่าต้องการออกจากระบบ?',
      qrSave: 'บันทึก QR Code สำเร็จ',
      changePass: 'เปลี่ยนรหัสผ่านสำเร็จ',
      timeUp: 'หมดเวลาทำรายการ ธุรกรรมหมดอายุ',
      transSucess: 'โอนเงินสำเร็จ',
      witSuccess: `ยืนยันการถอนสำเร็จ\n
  กระบวนการนี้อาจใช้ระยะเวลาดำเนินการสูงสุด 15 นาที`,
      commSuccess: 'ถอนค่าคอมมิชชั่นสำเร็จ',
      topProcess:
        'การเติมเงินอยู่ในระหว่างดำเนินการ ไม่สามารถถอนได้จนกว่าจะเติมเงินสำเร็จ',
      sessionExp: 'เซสชั่นหมดอายุ',
      verifyBank: `ตรวจสอบบัญชีธนาคารที่อยู่ระหว่างดำเนินการ\nโปรดกลับมาใหม่`,
      successful: 'ประสบความสำเร็จ !',
      missionComp: 'ภารกิจเสร็จสิ้น',
      claimReward: 'กรุณากดรับรางวัลของคุณก่อนดำเนินการ',
      gameInProgress: 'เกมอยู่ระหว่างดำเนินการ',
    },

    err: {
      maintainence: `ปิดอัพเดตและปรับปรุงระบบ กลับมาใช้ได้เวลา.\n04:00 PM (GMT+ 7)`,
      timeout: 'หมดเวลาทำรายการ โปรดลองใหม่อีกครั้งในภายหลัง',
      networkFail: 'เครือข่ายล้มเหลว โปรดลองใหม่อีกครั้ง',
      someErr: 'เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งในภายหลัง',
      pinMax: 'สูงสุด 6 หลักเท่านั้น',
      permission: 'คำขออนุญาต',
      qrFailed: 'บันทึก QR Code ล้มเหลว',
      wrongPass: 'รหัสผ่านไม่ถูกต้อง',
      passRequire: 'รหัสผ่านไม่ตรงกับข้อกำหนด',
      pin: 'รหัสใหม่ & ยืนยันรหัสมีข้อมูลไม่ตรงกัน',
      amtMax: 'สูงสุด 5 หลักเท่านั้น',
      amtZero: 'จำนวนเงินไม่สามารถเริ่มต้นจากศูนย์',
      amtEmpty: 'โปรดระบุจำนวนเงิน',
      insufficient: 'ยอดเงินคงเหลือไม่เพียงพอ',
      noBank: 'ไม่พบธนาคาร โปรดเพิ่มชื่อธนาคาร (รายการธนาคาร)',
      minAmt: 'จำนวนเงินขั้นต่ำ',
      verifyBank:
        'ไม่สามารถยืนยันข้อมูลได้ กรุณาระบุหมายเลขบัญชีธนาคารที่ถูกต้อง',
      angpao: 'อั่งเปารับสิทธิ์ภายใน 24 ชม. เท่านั้น (หมดอายุ)',
      depositMaintenance: 'บริการฝากเงินอยู่ในระหว่างปรับปรุง',
      witMaintenance: 'บริการถอนเงินอยู่ในระหว่างปรับปรุง',
      tryAgain: 'กรุณาลองอีกครั้ง',
      lineLogin: 'เกิดข้อผิดพลาดในการล็อคอิน LINE',
      gameNotFound: 'ไม่พบข้อมูลเกม!!!',
      gameNotFoundDesc: `ไม่สามารถตรวจพบแอป {{appGame}} กรุณาดาวน์โหลดแอป {{appGame}} โดยการคลิกที่ปุ่มด้านล่าง และคลิก "เล่น" หลังจากติดตั้งสำเร็จ`,
      downloadLinkErrDesc:
        'ลิงก์ดาวน์โหลดไม่สามารถใช้งานได้ในขณะนี้ โปรดลองอีกครั้งในภายหลัง',
      userNotExist: 'ไม่พบผู้ใช้งาน',
      gameNotAvailable:
        'โปรโมชั่นนี้ถูกใช้งานแล้ว ไม่สามารถเล่นเกมนี้ได้ในขณะนี้',
      somethingWrong: 'เกิดข้อผิดพลาด กรุณาติดต่อแอดมินเพื่อรับความช่วยเหลือ',
    },
  },

  toast: {
    resetPass: 'ตั้งรหัสผ่านใหม่สำเร็จ',
    setPin: 'ตั้งรหัส PIN สำเร็จ',
    copyAccName: 'เลขที่บัญชีธนาคาร',
    copyAccNum: 'เลขที่บัญชีธนาคาร\nคัดลอกไปยังแดชบอร์ด',
    copyAmt: 'จำนวนเงิน\nคัดลอกไปยังแดชบอร์ด',
    status: 'สถานะ',
    updateErr: 'การอัปเดตล้มเหลว โปรดรีสตาร์ทแอปพลิเคชั่นใหม่อีกครั้ง',
    updateBal: 'อัปเดตยอดคงเหลือ',
  },

  bankAccName: {
    title: 'ชื่อบัญชีธนาคารต้องเกี่ยวกับการทำรายการ (ตัวอย่างเช่น: ถอนเงิน)',
    important:
      'โปรดทราบ: ท่านจะไม่สามารถเปลี่ยนแปลงชื่อบัญชีธนาคารได้เมื่อได้รับการยืนยันแล้ว',
    understand: 'ฉันเข้าใจ',
  },

  bankAccNum: {
    title: `เพื่อการรับประสบการณ์ความสนุกเต็มรูปแบบ กรุณาระบุชื่อบัญชีธนาคารของท่านเพื่อใช้ในการดำเนินการต่าง ๆ ที่เกี่ยวข้องกับกระเป๋าเงิน (เช่น การถอน ฯลฯ)`,
    important: `โปรดทราบ: โปรดทราบว่าชื่อบัญชีธนาคารจะไม่สามารถเปลี่ยนแปลงได้เมื่อได้รับการยืนยันแล้ว`,
  },

  signIn: {
    line: 'แชทกับเราทางไลน์',
    lang: 'ภาษา',
    or: 'หรือ',
    loginLine: 'ล็อคอินด้วย LINE',
    login: 'เข้าสู่ระบบ',
    register: 'ลงทะเบียน',
  },

  verifyMobile: {
    verifyTitle: 'ยืนยันหมายเลขโทรศัพท์ของคุณ',
    otpSent: 'OTP ถูกส่งไปยังเบอร์โทรศัพท์ด้านบนแล้ว',
    resendIn: 'ส่งรหัสอีกครั้งใน: ',
  },

  signUp: {
    ref: {
      tips1: 'โปรดระบุชื่อผู้อ้างอิงในการลงทะเบียน',
      tips2: 'สแกน QR Code หรือป้อนรหัสอ้างอิงเพื่อลงทะเบียนต่อ',
      tips3: 'คุณได้รับการอ้างอิงจาก:',
      tips4: 'สแกน QR Code หรือป้อนรหัสอ้างอิง',
    },
    otp: {
      tips1: 'รหัส OTP ถูกส่งไปยังเบอร์โทรศัพท์ของท่านแล้ว',
      tips2: 'โปรดป้อนรหัสที่มีคำนำหน้าเดียวกันกับที่แสดงด้านล่าง',
      tips3: 'ส่งรหัสใหม่อีกครั้งใน',
    },
    err: {
      refCode: 'รหัสอ้างอิงไม่ถูกต้อง',
    },
    pwd: {
      tips: 'ข้อกำหนดรหัสผ่าน :',
      min: 'ขั้นต่ำ',
      max: 'ขีดสุด',
      char: 'ตัวอักษรและตัวเลข',
      pwd: 'รหัสผ่าน',
      cpwd: 'ยืนยันรหัสผ่าน',
      request: 'ขอ',
      verify: 'ตรวจสอบ',
      otp: 'OTP',
      check: 'ตรวจสอบ',
      skip: 'ข้าม',
      ref: 'รหัสผู้อ้างอิง',
    },
  },

  forgotPin: {
    msg: {
      1: 'เราจะส่งรหัส PIN ชั่วคราวของคุณไปยังหมายเลขโทรศัพท์ด้านล่าง',
      2: 'กรุณาคลิกเพื่อรับรหัส PIN ชั่วคราว',
    },
    title: {
      tempPin: 'รหัส PIN ชั่วคราว',
    },
  },

  forgotPass: {
    label: {
      newPass: 'รหัสผ่านใหม่',
      confirmPass: 'ยืนยันรหัสผ่าน',
    },
  },

  homepage: {
    act: {
      title: `ตรวจสอบ ${clientInfo.appname}`,
      more: 'ดูเพิ่ม',
      topUp: 'เติมเงิน',
      fund: 'เติมเงิน',
      withdraw: 'ถอนเงิน',
      transfer: 'โอนเงิน',
      bankList: 'รายการธนาคาร',
      trxnList: 'ธุรกรรม',
      gameRec: 'บันทึกเกม',
      gameComm: 'คอมมิชชั่น',
      affiliate: 'พันธมิตร',
      notification: 'การแจ้งเตือน',
      profile: 'โปรไฟล์ของฉัน',
      cs: 'ช่วยเหลือ',
      mission: 'ภารกิจ',
      chat: 'แชท',
      downline: 'ดาวน์ไลน์ของฉัน',
      learnMore: 'เรียนรู้เพิ่มเติม',
      web: 'เว็บไซต์',
      premium: 'มาเป็นตัวแทนพรีเมี่ยม',
      feedback: 'ข้อเสนอแนะ',
      language: 'ภาษา',
      club: 'Club',
      home: 'หน้าหลัก',
      game: 'เกม',
      reward: 'รางวัล',
      vip: 'วีไอพี',
    },
    wheel: {
      title: 'วงล้อนำโชคโบนันซ่า',
      desc: `ทุกยอดฝากเงินครั้งละ 100 บาทขึ้นไป มีสิทธิ์ลุ้นรับโบนัสในกิจกรรมวงล้อนำโชคโบนันซ่า 1 ครั้ง!\n\nหมุนวงล้อของคุณเพื่อเพลิดเพลินกับโบนัสสุ่มแจกสูงสุด 100% ด้วยเทิร์นโอเวอร์ x1 เท่านั้น\n\nไม่มีข้อกำหนด & เงื่อนไขแอบแฝง`,
      claimMsg_1: 'คุณได้รับรางวัล',
      claimMsg_2: 'จาก',
      turnover: 'เทิร์นโอเวอร์',
      noTurnover: '(ไม่มีเทิร์นโอเวอร์)',
    },
    promo: {
      header: 'ยินดีด้วยค่ะเถ้าแก่!',
      body: `วันนี้! ส่งความสุขให้เพื่อนของคุณเพื่อร่วมรับอั่งเปา!\nเมื่อเพิ่มเพื่อนเป็นดาวน์ไลน์ คุณก็จะได้ค่าคอมมิชชั่นเมื่อพวกเขาเล่น!`,
      body2: `ขณะนี้มีผู้โชคดีได้รับอั่งเปาจำนวนมาก\nท่านจะได้รับอั่งเปาในอีกสักครู่ค่ะ`,
      share: 'แชร์',
      receive: 'รับ',
      time: 'ตรวจสอบอีกครั้งโดย',
      note: 'หมายเหตุ: ใช้อั่งเปาภายใน 24 ชม',
    },
    activity: {
      title: 'อั่งเปาฟรีมาอีกแล้ว! เพื่อนของคุณจะรักคุณมากยิ่งขึ้น!',
      decs: `เชิญชวนเพื่อนของคุณให้สมัครเข้าร่วมกับ ${clientInfo.appname} เพื่อให้สิทธิ์พวกเขารับอั่งเปาสุ่มแจกในนามของคุณ มูลค่ารวมกว่า 2,000,000 บาท เมื่อลงทะเบียนสำเร็จ! มาก่อน รับสิทธิ์ก่อน! รีบชวนเพื่อนของคุณให้สมัครเลยตอนนี้!`,
    },
    intro: {
      next: 'ถัดไป',
      end: 'เล่น & รับทันที',
      card_1: {
        title: 'ใครๆ ก็เป็นเถ้าแก่ได้',
        desc: 'เล่น + รับ กับเถ้าแก่เบท ที่แรกในตลาดที่ให้คุณเพลิดเพลินกับ PG, Joker, UFA และอื่นๆ อีกมากมายในแอปเดียว สร้างรายได้พิเศษจากภารกิจ เงินคืนยอดเสีย และคอมมิชชั่น',
      },
      card_2: {
        title: 'ความสนุกสุดพิเศษ',
        desc: 'รับเงินคืนยอดเสียอัตราสูงแบบไม่จำกัดได้ทุกวันจากทุกเกมโปรดของคุณในที่เดียว',
      },
      card_3: {
        title: 'สร้างรายได้พิเศษอย่างง่ายดาย',
        desc: 'แชร์ให้เพื่อนของคุณเพื่อรับคอมมิชชั่นแบบไม่จำกัดตลอดชีพ ไม่ว่าเพื่อนของคุณจะแพ้หรือชนะ',
      },
      card_4: {
        title: 'ภารกิจสุดมันส์แบบไม่มีเบื่อ',
        desc: 'รับรางวัลที่น่าตื่นเต้นเร้าใจได้ทุกเวลา เพียงแค่ทำภารกิจง่ายๆ',
      },
      card_5: {
        title: 'เล่นง่าย',
        desc: 'ระบบอัตโนมัติแบบเต็มรูปแบบที่รวดเร็ว & เชื่อถือได้ และ ไม่มีข้อกำหนดฝาก & ถอนขั้นต่ำ',
      },
    },
    news: {
      cancel: 'ยกเลิก',
      news_1: {
        title: `${clientInfo.appname} กลับมาใช้งานได้ตามปกติแล้ว! `,
        desc_1:
          'ขออภัยในความไม่สะดวกที่เกิดขึ้นอีกครั้ง เนื่องจากเราพบการโจมตีที่เป็นอันตรายต่อระบบจากหลายฝ่าย จึงทำให้บริการทั้งหมดของเราหยุดชะงักตั้งแต่เมื่อคืนที่ผ่านมา',
        desc_2:
          'ทีมงานของเราได้ดำเนินการแก้ไขอย่างเร่งด่วนเพื่อป้องกันและแก้ไขสถานการณ์เรียบร้อยแล้ว ขณะนี้เราภูมิใจที่จะประกาศว่าทุกอย่างได้กลับสู่สภาวะปกติแล้ว ท่านสามารถทำรายการฝากและถอนเงินได้ตามปกติ',
        desc_3: `ขอบคุณสำหรับการอดทนรอของท่านเป็นอย่างยิ่ง ${clientInfo.appname} มุ่งมั่นที่จะให้บริการที่ดีขึ้นยิ่งขึ้นกับลูกค้าของเราทุกคน`,
        desc_4: '#ใครๆก็เป็นเถ้าแก่ได้”',
      },
      news_2: {
        title: 'โบนัสเช็คอิน',
        desc: `เข้าสู่ระบบ ${clientInfo.appname} ทุกวันที่ 1 & 15 ของเดือนเพื่อรับรางวัลฟรี\n\nดูข้อมูลภารกิจเพื่อความตื่นเต้นที่มากยิ่งขึ้น`,
        desc_1: `เข้าสู่ระบบ ${clientInfo.appname} ทุกวันที่ 1 & 15 ของเดือนเพื่อรับรางวัลฟรี`,
        desc_2: 'ดูข้อมูลภารกิจเพื่อความตื่นเต้นที่มากยิ่งขึ้น',
        goto: 'ไปที่หน้าภารกิจ',
      },
      news_3: {
        title: 'ส่วนลดเติมเงินสุดพิเศษในเวลาจำกัด',
        desc: `รับเงินไปเล่นได้เต็มจำนวนแต่จ่ายน้อยลง รับประกันว่าไม่มีเงื่อนไขแอบแฝงที่ซับซ้อนในการถอนเงิน เติมเงินตอนนี้เพื่อรับส่วนลดพิเศษภายในเวลาจำกัดกับเราได้เลย`,
        goto: 'เติมเงินตอนนี้',
      },
      rebate: {
        title: 'คืนยอดเสีย 100% ในการฝากเงินหนึ่งครั้ง!',
        desc: `เงินฝากของคุณจะได้รับการคุ้มครองหนึ่งครั้งทันทีเป็นเวลา 24 ชม\nหากคุณชนะ คุณสามารถรับเงินรางวัลทั้งหมดไปได้เลย\nหากคุณแพ้ คุณจะได้รับยอดเสียคืน 100% อย่างแน่นอน!\n\nโปรคืนยอดเสีย 100% สามารถใช้งานกับยอดฝากถึง 5000 บาท (สูงสุด)\nคุณจะได้รับยอดเสียคืน 100% ติดต่อกันเป็นเวลา 10 วัน \nรับเงินคืนได้ทุกวันที่นี่ก่อนที่โปรโมชั่นนี้จะหมดอายุ`,
        cusTitle: `หมายเหตุ:`,
        cus_1: '- ภารกิจนี้สามารถใช้งานได้เพียงหนึ่งครั้งเท่านั้น',
        cus_2:
          '- โปรคืนยอดเสีย 100% นี้จะใช้งานได้ก็ต่อเมื่อคุณทำยอดเสียภายใน 24 ชม. หลังจากฝากเงินรอบแรก',
        cus_3:
          '- ในกรณีที่ไม่มียอดเสียเกิดขึ้นภายใน 24 ชม. หรือ มีการถอนใดๆ เกิดขึ้น ภารกิจนี้จะไม่สามารถใช้งานได้อีก',
        cus_4:
          '- เงินคืนยอดเสียใดๆ ที่ไม่มีการอ้างสิทธิ์จะถูกยกเลิกโดยอัตโนมัติทันทีเมื่อครบกำหนด 24 ชม',
      },
      UEFA: {
        title: 'ทายแชมป์ UEFA EURO 2020',
        desc: `ทายผลลุ้นรางวัลใหญ่! ทำนายผลการแข่งขันและเลือกทีมที่คุณคิดว่าจะคว้าแชมป์ UEFA EURO 2020 เพื่อรับส่วนแบ่งเงินรางวัลมูลค่ารวมกว่า 10,000,000 บาททันทีสำหรับผู้เข้าร่วมกิจกรรมทุกท่านที่ทายถูกว่าทีมไหนจะได้แชมป์ในปีนี้\n\nรับสิทธิ์ทายผลเพิ่มจากการเชิญชวนเพื่อน 1 คนให้มาลงทะเบียนเข้าร่วม เมื่อทีมที่คุณทายไม่ผ่านเข้ารอบ 16 ทีม\n\nรับสิทธิ์ทายผลเพิ่มอีกครั้งจากการเชิญชวนเพื่อน 3 คนให้มาลงทะเบียนเข้าร่วม หากทีมที่คุณคาดการณ์ไว้ไม่ผ่านเข้าสู่รอบรองชนะเลิศ\n\nยิ่งคุณเข้าร่วมภารกิจนี้เร็วเท่าไหร่ ส่วนแบ่งเงินรางวัลที่คุณจะได้รับหากทายผลถูกต้องก็จะยิ่งสูงขึ้นเท่านั้น!`,
      },
    },
  },

  game: {
    games: 'เกมส์',
    all: 'ทั้งหมด',
    sports: 'กีฬา',
    ecasino: 'คาสิโน',
    slot: 'สล็อต',
    fish: 'ยิงปลา',
    funky: 'ขี้ขลาด',
    table: 'ตาราง',
    poker: 'โป๊กเกอร์',
    arcade: 'อาร์เคด',
    live: 'เกมสด',
    bingo: 'บิงโก',
    lottery: 'หวย',
    club: 'Club',
    hot: 'ฮ็อต',
    new: 'ใหม่',
    sort: 'A-Z',
    fav: 'ชอบ',
    noGame: 'ไม่มีเกม',
    loading: 'กำลังโหลด ...',
    cockfight: 'ชนไก่',
    label: {
      all: 'เกมส์ทั้งหมด',
      sports: 'เกมกีฬา',
      ecasino: 'เกมคาสิโน',
      slot: 'เกมสล็อต',
      fish: 'เกมยิงปลา',
      cockfight: 'เกมชนไก่',
      funky: 'เกมส์ขี้ขลาด',
      table: 'เกมส์ตาราง',
      poker: 'เกมส์โป๊กเกอร์',
      arcade: 'เกมส์อาเขต',
      live: 'เกมส์มีชีวิต',
      bingo: 'เกมส์บิงโก',
      club: 'Club',
    },
    allProvider: 'ผู้ให้บริการทั้งหมด',
    others: 'คนอื่น',
    searchGame: 'ค้นหาชื่อเกมส์',
    provider: 'ผู้ให้บริการ',
    search: 'ค้นหา',
    providerDesc: {
      pg: 'สัมผัสประสบการณ์การเล่นเกมที่น่าตื่นตาตื่นใจพร้อมรางวัลมากมายในเกมสล็อตล้ำสมัยจากค่าย PG Soft',
      joker:
        'ดำดิ่งสู่ความตื่นเต้นและเร้าใจแบบไม่มีที่สิ้นสุดกับเกมสล็อตออนไลน์ที่น่าหลงใหลจากค่าย Joker',
      jili: 'ปลดปล่อยศักยภาพแห่งชัยชนะของคุณในเกมออนไลน์ที่สมจริงที่สุดด้วยเทคโนโลยีที่ล้ำสมัยจากค่าย Jili',
      mp: 'ดื่มด่ำกับความบันเทิงที่เหนือชั้นด้วยเกมยอดนิยมที่ได้รับรางวัลและกราฟิกที่น่าทึ่งของ Microgaming',
      wssport:
        'ยกระดับการเดิมพันกีฬาอย่างเหนือชั้นในแพลตฟอร์ม WS Sport สุดครบครันด้วยอัตราต่อรองที่ดีที่สุด',
      saba: 'ดื่มด่ำกับการเดิมพันกีฬาที่น่าตื่นเต้นด้วยโซลูชั่นที่โดดเด่นของ Saba Sports',
      pp: 'เพลิดเพลินกับนวัตกรรมและความบันเทิงจากคาสิโนออนไลน์ชั้นนำที่น่าหลงใหล Pragmatic Play',
      fc: 'เข้าสู่โลกแห่งโชคลาภและความมั่งคั่งใน Fa Chai เพลิดเพลินกับเกมที่หลากหลายและตอบโจทย์ความต้องการของคุณ',
      km: 'เริ่มต้นการเดินทางสู่ความมั่งคั่งด้วยตัวเลือกเกมที่น่าสนใจและเต็มไปด้วยรางวัลที่น่าหลงใหลใน Kingmaker',
      spade:
        'สำรวจการผจญภัยที่ยากจะลืมเลือนใน Spade Gaming เพลิดเพลินกับเกมสล็อตสุดตระการตาที่มาพร้อมธีมและรางวัลที่น่าตื่นตาตื่นใจ ',
      haba: 'ยกระดับประสบการณ์การเดิมพันอย่างเหนือชั้นด้วยเกมสล็อต Habanero ที่ปฏิวัติวงการซึ่งนำเสนอคุณสมบัติและการเล่นเกมที่ล้ำสมัย ',
      rt: 'คว้าชัยชนะที่ยิ่งใหญ่ในเกมสล็อตออนไลน์ Red Tiger ที่น่าทึ่งนี้ เพลิดเพลินกับกราฟิกที่ล้ำสมัยและรางวัลที่น่าดึงดูดใจที่สุด',
      yl: 'สนุกกับความเป็นไปได้ไร้ขีดจำกัดด้วยรูปแบบการเล่นที่เป็นเอกลักษณ์และชวนดื่มด่ำของ Youlian Gaming',
      sexy: 'ดื่มด่ำกับเสน่ห์ของเกมคาสิโนสดที่น่าหลงใหลจากค่าย Sexy Baccarat ที่ผสมผสานความสง่างามและความตื่นเต้นได้อย่างลงตัว',
      venus:
        'ค้นพบความยอดเยี่ยมจากการเล่นเกมที่ Venus Casino ที่ซึ่งคุณจะได้พบกับตัวเลือกเกมที่ครบครันพร้อมเงินรางวัลที่น่าตื่นตาตื่นใจ',
      sv388:
        'ดื่มด่ำกับโลกแห่งไก่ชนออนไลน์ใน SV388 แพลตฟอร์มคาสิโนที่ลื่นไหลสำหรับผู้ชื่นชอบเกมออนไลน์ทุกคน',
      e1: 'สัมผัสความตื่นเต้นแห่งชัยชนะกับ E1 Sport ผู้เชี่ยวชาญการบริการเกมเดิมพันกีฬาเสมือนจริง',
      pt: 'เข้าสู่โลกแห่งความมันส์ไร้ขีดจำกัดที่ Playtech ผู้ให้บริการเกมที่โดดเด่นในด้านเทคโนโลยีและรูปแบบการเล่นที่ล้ำสมัย',
      fastspin:
        'กระตุ้นอะดรีนาลีนของคุณด้วยเกมคาสิโนคุณภาพสูงสุดมันส์จากค่าย Fastspin',
      luckypoker:
        'ดำดิ่งสู่โลกแห่งเกมไพ่เสมือนจริงที่ Lucky Poker เพลิดเพลินกับตัวเลือกเกมที่หลากหลาย ทัวร์นาเมนต์มากมาย และรางวัลใหญ่ที่คุ้มค่าที่สุด',
      sbo: 'เดิมพันอย่างมั่นใจใน Sbobet ผู้ให้บริการเกมเดิมพันกีฬาและคาสิโนสดที่หลากหลาย ครบครัน และเหนือชั้นที่สุด',
      yesbingo:
        'สนุกกับเกมบิงโกหลากหลายรูปแบบพร้อมรางวัลมากมายได้ที่ Yes Bingo ผู้ให้บริการเกมคาสิโนยอดนิยมสำหรับผู้ชื่นชอบบิงโกทั่วโลก',
      pussy:
        'เตรียมดื่มด่ำกับกราฟิกที่สวยงาม ประสบการณ์การเล่นที่ราบรื่น และแจ็คพอตที่น่าประทับใจในเกมสล็อตมือถือที่ดีที่สุดจากค่าย Pussy888',
      kiss918:
        'ด้วยกราฟิกที่น่าทึ่ง แผงควบคุมที่ใช้งานง่าย และโบนัสที่น่าตื่นตาตื่นใจ ทำให้ 918Kiss โดดเด่นในฐานะผู้ให้บริการสล็อตมือถือที่ได้รับความนิยมสูงสุด',
      mega888:
        'สัมผัสประสบการณ์เกมสล็อตที่น่าตื่นเต้นและแอคชั่นคาสิโนสดกับ Mega888 ด้วยตัวเลือกเกมที่น่าหลงใหลพร้อมรับรางวัลมากมาย และ Mega Wins!',
    },
    info: {
      line: 'ไลน์',
      rtp: 'RTP',
      volatility: 'ค่าผันผวน',
      betLimit: 'เบทสูงสุด',
      maxWin: 'รางวัลสูงสุด',
      feature: 'ฟีเจอร์',
      ways: 'เพย์ไลน์',
      credit: 'เครดิต',
      cluster: 'คลัสเตอร์',
    },
  },

  club: {
    title: 'ความบันเทิงครบครัน',
  },

  movie: {
    imdb: 'IMDb',
    play: 'เล่น',
    rottenTomatoes: 'มะเขือเทศเน่า',
    metacritic: 'ริติค',
    googleUsers: 'ผู้ใช้ Google',
    likeThis: 'ชอบหนังเรื่องนี้',
    releaseDate: 'วางจำหน่ายครั้งแรก: ',
    directors: 'ผู้กำกับ: ',
    boxOffice: 'บ็อกซ์ออฟฟิศ: ',
    budget: 'งบประมาณ: ',
    awards: 'รางวัล: ',
    all: 'ทั้งหมด',
    hot: 'ร้อน',
    recent: 'ล่าสุด',
  },

  sportTv: {
    watchNow: 'ดูตอนนี้',
    live: 'สด',
  },

  UEFA: {
    modalConfirm: 'ยืนยันทายผลประเทศนี้ ?',
    confirm: 'ยืนยันทายผลประเทศนี้',
    choose: 'เลือกประเทศ',
    active: 'Active',
    inactive: 'ไม่ผ่านเข้ารอบ',
    predicted: 'ประเทศที่ทำนายไว้',
  },

  feedback: {
    title: `คุณสนุกกับ ${clientInfo.appname} หรือไม่ ?`,
    tapDesc: `โปรดให้ดาวเพื่อให้คะแนน ${clientInfo.appname}`,
    howDesc: 'อยากให้เราปรับปรุงในส่วนไหน ?',
    eg: ' เช่น ฉันรักเถ้าแก่เบท...',
    submit: 'ส่ง',
  },

  deposit: {
    active: 'เปิดใช้งานอยู่',
    inactive: 'ไม่ได้ใช้งาน',
    auto: {
      title: 'เงินฝากอัตโนมัติ',
      desc: 'ฝากเงินอัตโนมัติภายใน 1 นาที',
    },
    decimal: {
      title: 'ฝากเงินแบบทศนิยม',
      desc: 'กรุณาโอนเงินด้วยจำนวนทศนิยมที่ถูกต้องเพื่อการฝากเงินภายใน 3 นาที',
    },
  },

  autoDeposit: {
    1: ' 1. กรุณาโอนเงินเข้าบัญชีธนาคารด้านล่างด้วยธนาคารที่คุณลงทะเบียนไว้',
    2: ' 2. ระบบจะดำเนินการฝากเงินของคุณโดยอัตโนมัติภายใน 1 นาที',
    label: {
      step: 'ขั้นตอนที่: ',
    },
  },

  topUp: {
    tips: {
      //1: 'ขั้นตอนที่ 1: กรุณากรอกจำนวนเงินที่ท่านต้องการเพื่อเติมเงิน  นับเวลาถอยหลัง 3 นาที',
      //2: 'ขั้นตอนที่ 2: กรุณาคัดลอกชื่อบัญชีธนาคารและเลขที่บัญชี',
      //3: 'ขั้นตอนที่ 3: ไปที่หน้าธนาคารของท่าน  กรุณาวางข้อมูลเพื่อดำเนินการโอนเงิน',
      confirm: 'รับทราบ',
      hide: 'ไม่แสดงหน้านี้อีก',
      howToDeposit: 'วิธีการฝากเงิน',
      1: `กรอกจำนวนเงินที่คุณต้องการเติมเงินใน 3 นาที `,
      2: `คัดลอกข้อมูลบัญชีธนาคาร`,
      3: `วางข้อมูลลงในธนาคาร & การโอนเงินของคุณ`,
    },

    label: {
      amtTxt: 'ระบุจำนวนเงินที่ท่านต้องการ',
      countdown: 'นับเวลาถอยหลัง',
      bank: 'ธนาคาร:',
      accName: 'ชื่อบัญชี:',
      accNum: 'เลขที่บัญชี:',
      step1: `ขั้นตอนที่ 1: `,
      step2: `ขั้นตอนที่ 2: `,
      step3: `ขั้นตอนที่ 3: `,
      remark: 'กรุณาโอนจำนวนเงินและทศนิยมให้ถูกต้อง',
      remark2: 'กระเป๋าเงินของคุณจะถูกอัปเดตภายใน 2 นาทีเมื่อทำธุรกรรมสำเร็จ',
      report: 'แจ้งปัญหาที่เกิดขึ้น',
      lineBank: 'ธนาคารผู้ใช้งาน',
      lineBankAcct: 'หมายเลขบัญชีผู้ใช้งาน',
      lineAmount: 'จำนวนเงิน',
      amount: 'จำนวนเงิน',
    },

    promo: {
      title: 'แจ้งเตือนการฝากเงินแบบทศนิยม:',
      1: '1. กรุณาโอนเงินด้วยตัวเลขทศนิยมที่ถูกต้อง',
      2: '2. ห้ามปัดเศษทศนิยม',
      3: '3. หลังจากส่งคำขอฝากเงินเรียบร้อย กรุณาทำการฝากเงินให้แล้วเสร็จภายใน 10 นาที',
      4: '4. หากเกินระยะเวลาที่กำหนดดังกล่าว ท่านจะต้องส่งคำขอฝากเงินในระบบอีกครั้ง',
      promo:
        'จ่ายน้อยลง & รับเต็มจำนวน ไม่มีเงื่อนไขเพิ่มเติมในการถอนสำหรับการอ้างสิทธิ์รับส่วนลด',
      get: `จำนวนเงินที่คุณจะได้รับ`,
      title_2: 'โบนัสแบบสุ่ม (ใหม่)',
      content:
        'เติมเงินเพื่อรับโบนัสแบบสุ่มฟรีจาก 0% ถึง 100% ด้วยเทิร์นโอเวอร์ x1 เท่านั้น !!!',
    },
  },

  withdraw: {
    label: {
      bankAcc: 'บัญชีธนาคาร',
      witTxt: '* สามารถถอนเงินได้สูงสุด',
      YourWal: 'กระเป๋าเงินของคุณ',
      YourComm: 'คอมมิชชั่นของคุณ',
      balNotEnuf: '* ยอดคงเหลือไม่เพียงพอ',
      minWit: '* จำนวนเงินขั้นต่ำ',
      turnover:
        '* กรุณารออีก 10 นาที ยอดเทิร์นโอเวอร์ของท่านยังประมวลผลไม่เสร็จสิ้น',
    },
  },

  transfer: {
    transTxt: 'สามารถโอนเงินได้สูงสุด',
    BankTxt: 'โอนเข้าธนาคาร',
    WalTxt: 'ถอนไปยังธนาคาร',
  },

  bankList: {
    noBank: 'ไม่พบบัญชีธนาคาร',
    deleteMsg: 'คุณต้องการลบธนาคารนี้ออกหรือไม่?',
    changeName: 'เปลี่ยนชื่อบัญชีเรียบร้อยแล้ว!',
  },

  fundTransfer: {
    btn: {
      transaction: 'การทำธุรกรรม',
      gameRec: 'บันทึกเกม',
      bankDetails: 'รายละเอียดธนาคาร',
      details: 'รายละเอียด',
    },
    title: {
      depositStatus: 'กำลังดำเนินการฝาก',
    },
    card: {
      wdrlBal: 'ยอดเงินคงเหลือที่ถอนได้',
      turnover: 'เทิร์นโอเวอร์ที่เหลือยู่',
    },
    tips: {
      title: 'เคล็ดลับโปร',
      tips: `รับเงินคืน 0.3% แบบไม่จำกัดสำหรับทุกมือที่คุณเล่น\nเงินคืนที่ได้รับจะถูกโอนเข้ากระเป๋าคอมมิชชั่นของคุณทุกวัน`,
    },
  },

  commDetails: {
    tips: {
      title: 'เคล็ดลับโปร',
      tips: `รับรายได้พิเศษ (คอมมิชชั่น) ด้วยการเล่นเกม ชวนเพื่อนให้เข้าร่วม หรือ ทำภารกิจในเกมให้สำเร็จ\nเรียนรู้วิธีรับค่าคอมมิชชั่นตอนนี้`,
      learnMore: '- เรียนรู้เพิ่มเติม -',
    },
  },

  gameRec: {},

  gameComm: {},

  affiliate: {
    friend: 'เชิญชวนเพื่อน',
    save: 'บันทึก QR Code',
    downlineList: 'ดาวน์ไลน์',
    reconnectRef: 'เชื่อมต่อการอ้างอิงอีกครั้ง',
    successReconnect: 'เชื่อมต่อใหม่สำเร็จ',
    learnMore: 'เรียนรู้เพิ่มเติม',
    lifetime: 'รายได้ตลอดชีพ ',
    unsettleComm: 'คอมมิชชั่นค้างชำระ ', //Unpaid commission
    consolidate: 'กำหนดจ่าย ', //payment due
    url: 'URL พันธมิตรของคุณ ',
    shareNow: 'แชร์เลย',
    shareLine: 'แชร์ไปที่ LINE',
    copy: 'คัดลอกลิงก์พันธมิตร',
    report: 'รายงานผลคอมมิชชั่น',
    level: 'สรุประดับที่',
    summary: '',
    totalRegister: 'จำนวนผู้สมัครทั้งหมด',
    totalNewDeposit: 'จำนวนเงินฝากใหม่ทั้งหมด',
    totalActiveDepositor: 'จำนวนผู้ใช้ทั้งหมดที่เติมเงิน',
    totalWinLost: 'ยอดได้/เสียรวม',
    totalPromotionalCost: 'ค่าใช้จ่ายส่งเสริมการขายทั้งหมด',
    totalRoyaltiesCost: 'ค่าลิขสิทธิ์ทั้งหมด',
    totalCost: 'ค่าใช้จ่ายทั้งหมด',
    totalCostDesc: 'ภารกิจ โปรโมชั่น ค่าลิขสิทธิ์ และอื่น ๆ จากดาวน์ไลน์ของคุณ',
    totalCommission: 'ค่าคอมมิชชั่นทั้งหมด',
  },

  notfication: {},

  profile: {
    choose: 'เลือกโพรไฟล์ของคุณ',
  },

  cs: {},

  chat: {
    tab: {
      info: 'ข้อมูล',
      LINE: 'LINE',
      Chat: 'แชท',
    },
    line: {
      title: 'แชทกับเราทางไลน์',
      btn: 'แชทตอนนี้',
      mobileNum: 'เบอร์มือถือ',
    },
  },

  lock: {
    witLockTitle: `สถานะ:\nระบบกำลังดำเนินการถอน ...`,
    witLockMg: `กดปุ่ม "ตรวจสอบสถานะ" เมื่อการถอนเสร็จสิ้น`,
    lockTitle: `สถานะ:\nบัญชีของท่านถูกล็อก`,
    lockMg: `กดปุ่มช่วยเหลือ "เพื่อติดต่อฝ่ายบริการลูกค้าของเรา`,
  },

  news: {
    title: 'ข่าวสาร',
    btn: 'ปิด',
  },

  codepush: {
    new: 'เวอร์ชั่นใหม่พร้อมให้บริการแล้ว',
  },

  mission: {
    moreInfo: 'คลิกเพื่อดูข้อมูลเพิ่มเติม',
    learnMore: 'เรียนรู้เพิ่มเติม',
    inProgress: 'กำลังดำเนินการ',
    claimed: 'อ้างสิทธิ์แล้ว',
    claimNow: 'เรียกร้องตอนนี้',
    expiry: 'สิ้นสุดกิจกรรม: ',
    status: 'สถานะกิจกรรม: ',
    progress: 'ความคืบหน้าของกิจกรรม: ',
    active: 'คล่องแคล่ว',
    requirement: 'ข้อกำหนด: ',
    turnover: 'เทิร์นโอเวอร์: ',
    pending: 'รอดำเนินการ',
    go: 'ไป',
    filter: {
      all: 'ทั้งหมด',
      inProgress: 'กำลังดำเนินการ',
      completed: 'เสร็จเรียบร้อย',
      record: 'บันทึก',
    },
  },

  downline: {
    all: 'ทั้งหมด',
    new: 'ใหม่',
    active: 'คล่องแคล่ว',
    inactive: 'ไม่ได้ใช้งาน',
    lastActive: 'ใช้งานล่าสุด: ',
    social: {
      call: 'โทร',
      sms: 'ข้อความ',
      line: 'ไลน์',
      chat: 'แชท',
    },
  },

  info: {
    label: {
      hide: 'ไม่แสดงหน้านี้อีก',
    },
    commDetail: {
      title: 'กระเป๋าเงินคอมมิชชั่น',
      desc_1:
        'กระเป๋าเงินคอมมิชชั่นจะแสดงยอดค่าคอมมิชชั่นล่าสุดที่คุณได้รับ ซึ่งรวมถึง เงินคืนยอดเสีย รางวัลจากภารกิจ รายได้จากยอดเทิร์นโอเวอร์ดาวน์ไลน์ของคุณ',
      desc_2:
        '- คุณสามารถโอนค่าคอมมิชชั่นไปยังกระเป๋าเงินหลักของคุณได้ทันทีเมื่อมียอดสะสมครบ',
      desc_2_amt: '100 บาท',
      desc_3:
        '- คุณสามารถถอนค่าคอมมิชชั่นไปยังบัญชีธนาคารของคุณได้ทันทีเมื่อมียอดสะสมครบ',
      desc_3_amt: '2,000 บาท',
    },
    fundTransfer: {
      title: 'กระเป๋าเงินหลัก',
      desc: 'กระเป๋าเงินหลักของคุณจะแสดงยอดเงินคงเหลือล่าสุดซึ่งขึ้นอยู่กับยอดแพ้-ชนะของคุณ ยอดเงินทั้งหมดที่คุณฝากเข้าสู่ระบบจะแสดงอยู่ในกระเป๋าเงินหลัก และเมื่อคุณทำการถอนเงินออกไป ยอดเงินนั้นจะถูกหักออกจากกระเป๋าเงินของคุณ',
    },
    mission: {
      title: 'ภารกิจ',
      desc: 'ทุกครั้งที่ทำภารกิจสำเร็จคุณจะได้รับรางวัลฟรี โดยที่รางวัลจะถูกโอนเข้าสู่กระเป๋าเงินคอมมิชชั่นของคุณ คลิกแต่ละภารกิจเพื่อดูข้อมูลภารกิจเพิ่มเติมได้เลย',
    },
    affiliate: {
      title: 'แชร์รหัสอ้างอิงของคุณ',
      decs: 'สร้างรายได้ง่าย ๆ ภายใน 5 ขั้นตอน',
      step_1: 'ขั้นตอนที่ 1: ',
      step_1_desc:
        'แชร์รหัสอ้างอิงของคุณ หรือ QR code หรือ ส่งลิงก์เชิญชวนไปให้เพื่อนของคุณ',
      step_2: 'ขั้นตอนที่ 2: ',
      step_2_desc: 'เพื่อนของคุณสมัครและเริ่มเล่น',
      step_3: 'ขั้นตอนที่ 3: ',
      step_3_desc: 'คุณจะได้รับผลตอบแทน 10% จาก ยอดได้ - เสีย ของเพื่อนของคุณ',
      step_4: 'ขั้นตอนที่ 4: ',
      step_4_desc: `ช่วยเพื่อนของคุณในการรับสมัครผู้เล่นอื่นเพิ่ม และ คุณจะได้รับผลตอบแทน 3% จาก ยอดได้ - เสีย ของพวกเขา`,
      step_5: 'ขั้นตอนที่ 5: ',
      step_5_desc: `รับผลตอบแทน 1% จาก ยอดได้ - เสีย ของดาวน์ไลน์ชั้นที่ 3`,
      remark:
        'เทิร์นโอเวอร์ คือ ยอดเงินเดิมพันทั้งหมดของคุณ คุณสามารถรับคอมมิชชั่นส่วนนี้ได้ไม่ว่าคุณจะแพ้หรือชนะเดิมพันก็ตาม! คุณจะได้รับเงินคืน 0.3% จากยอดเทิร์นโอเวอร์ของคุณโดยอัตโนมัติ เริ่มรับสมัครสมาชิกเพื่อรับเพิ่ม 10% , 3% และ 1% จากเครือข่ายเพื่อนของคุณได้เลย.',
    },
    depositProgress: {
      title: 'การฝากเงินอยู่ในระหว่างดำเนินการ',
      desc: 'ระบบจะฝากเงินแล้วเสร็จภายใน 1 นาที โปรดติดต่อฝ่ายบริการลูกค้าของเราหากใช้ระยะเวลาดำเนินการเกิน 15 นาที',
    },
    downline: {
      title: 'ดาวน์ไลน์',
      desc: 'จัดการบริหารดาวน์ไลน์สายตรงของคุณด้วยการตรวจสอบกิจกรรมล่าสุดของพวกเขา ดูแลและสร้างอาณาจักรเครือข่ายของคุณเพื่อรับคอมมิชชั่นตลอดชีพ',
    },
    howToPlay: {
      title: 'วิธีการเล่น',
      step_1: 'เร็วสุด ๆ',
      step_1_desc:
        'ลงทะเบียนด้วย LINE ID หรือ เบอร์โทรศัพท์ของคุณ ได้ใน 10 วินาที',
      step_2: 'เร็วยิ่งขึ้น',
      step_2_desc:
        'รับเงินฝากของคุณผ่าน ระบบอัตโนมัติใน 2 วินาที หลังจากการโอนเงิน',
      step_3: 'โบนัสจัดหนัก',
      step_3_desc:
        'รับสิทธิ์หมุนวงล้อนําโชค ลุ้นโบนัสสุ่มแจกสูงสุด 100%ทุกครั้งที่ฝากเงิน',
    },
  },

  err: {
    catch: `9999 : เกิดข้อผิดพลาด โปรดลองอีกครั้งในภายหลัง`,
    //#region #1:

    // Login, Param, Database
    WRONG_PASS: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
    MOBILE_NOT_EXIST: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
    WRONG_PIN: 'รหัส PIN ไม่ถูกต้อง',
    DOMAIN_NOT_REGISTER: 'ระบบขัดข้อง', //en: system failure
    RECORD_NOT_FOUND: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',

    SYSTEM_MAINTENANCE: 'ระบบอยู่ในระหว่างปรับปรุง',

    // Authenticate
    KEY_EXPIRE: 'การเชื่อมต่อล้มเหลว',
    KEY_INVALID: 'การเชื่อมต่อล้มเหลว',
    SIGN_INVALID: 'การเชื่อมต่อล้มเหลว',
    TOKEN_INVALID: 'การเชื่อมต่อล้มเหลว',
    SIGN_EMPTY: 'การเชื่อมต่อล้มเหลว',
    TOKEN_EMPTY: 'การเชื่อมต่อล้มเหลว',
    SYSTEM_ERROR: 'ระบบล้มเหลว',
    ACTION_INVALID: 'การดำเนินการไม่ถูกต้อง',
    DUPLICATED_REQUEST: 'โปรดลองใหม่อีกครั้งในภายหลัง', // request token

    // Param
    DID_MISS: 'ขาดข้อมูล DID',
    SECRET_MISS: 'ขาดข้อมูลลับเฉพาะ',
    COMPCODE_MISS: 'ขาดข้อมูลรหัสบริษัท',
    USERID_MISS: 'ขาดข้อมูลยูสเซอร์เนม',

    MOBILE_MISS: 'ขาดข้อมูลหมายเลขโทรศัพท์',
    MOBILE_SHORT: 'หมายเลขเบอร์โทรศัพท์ไม่ครบถ้วน',
    MOBILE_NUMERIC: 'ตัวเลขบนโทรศัพท์เท่านั้น',
    MOBILE_NO_PLUS: 'เบอร์โทรศัพท์ไม่มีเครื่องหมาย +',

    GAME_USERID_EMPTY: 'ยูสเซอร์เนมว่างเปล่า',
    GAME_USERID_LONG: 'ยูสเซอร์เนมยาวเกินกำหนด',

    OTP_MISS: 'ขาดข้อมูลรหัส OTP',
    PASS_MISS: 'ขาดข้อมูลรหัสผ่าน',
    PIN_MISS: 'ขาดข้อมูลรหัส PIN',
    REF_MISS: 'ขาดข้อมูลรหัสอ้างอิง',
    SESSIONID_MISS: 'ขาดข้อมูลรหัสเซสชั่น',
    OLDPASS_MISS: 'ขาดข้อมูลรหัสผ่านล่าสุด',
    OLDPIN_MISS: 'ขาดข้อมูลรหัส PIN ล่าสุด',
    BANK_CODE_MISS: 'ขาดข้อมูลรหัสธนาคาร',
    ACCNUM_MISS: 'ขาดข้อมูลเลขที่บัญชี',
    ACCNAME_MISS: 'ขาดข้อมูลชื่อบัญชี',
    TRXN_TIME_MISS: 'ขาดข้อมูลเวลาทำรายการ',
    TRXN_ID_MISS: 'ขาดข้อมูลเลขที่อ้างอิงการทำรายการ',
    AMOUNT_MISS: 'ขาดข้อมูลจำนวนเงิน',
    RECID_MISS: 'ขาดข้อมูลเลขที่ผู้รับ',
    GAMECODE_MISS: 'ขาดข้อมูลรหัสเกม',

    FIELD_MISS: 'ขาดข้อมูลฟิลด์พารามิเตอร์',
    FIELD_EMPTY: 'ฟิลด์ว่างเปล่า',
    FIELD_NUMBER: 'ตัวเลขในฟิลด์เท่านั้น',

    //Database
    DB_ERROR: 'DB_ERROR',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',

    SMS_ADD_FAILED: 'SMS_ADD_FAILED',
    PASS_UPDATE_FAILED: 'PASS_UPDATE_FAILED',
    SIGNUP_FAILED: 'SIGNUP_FAILED',
    PIN_UPDATE_FAILED: 'PIN_UPDATE_FAILED',
    LOGIN_RECORD_UPDATE_FAILED: 'LOGIN_RECORD_UPDATE_FAILED',
    GAME_TRXN_ADD_FAILED: 'GAME_TRXN_ADD_FAILED',
    WALLET_INSERT_FAILED: 'WALLET_INSERT_FAILED',
    //#endregion

    //#region #2

    // Wallet, Module
    REFCODE_INVALID: 'หมายเลขอ้างอิงไม่ถูกต้อง',
    COMPCODE_INVALID: 'เลขที่บริษัทไม่ถูกต้อง',
    PERMISSION_DENIED: 'คำขอถูกปฏิเสธ',

    MOBILE_NOT_REGISTER: 'Invalid mobile or password',
    MOBILE_REGISTERED: 'เบอร์โทรศัพท์ถูกใช้ลงทะเบียนแล้ว',
    REF_NOT_EXIST: 'ไม่พบหมายเลขอ้างอิง',
    DID_NOT_EXIST: 'ไม่พบ Device ID',
    DIFF_DID:
      'โปรดตรวจสอบโทรศัพท์ของท่าน\nรหัส OTP ถูกส่งไปให้ท่านเรียบร้อยแล้ว',
    LOGIN_DIFF_DEVICE: 'เซสชันหมดอายุบัญชีได้เข้าสู่ระบบในอุปกรณ์อื่น',

    // Profile
    BANK_ACCNAME_ADD_FAILED: 'เพิ่มชื่อบัญชีธนาคารไม่สำเร็จ',
    BANK_RECORD_ADD_FAILED: 'เพิ่มบันทึกธนาคารไม่สำเร็จ',
    BANK_RECORD_UPDATE_FAILED: 'อัปเดตบันทึกธนาคารไม่สำเร็จ',
    BANK_RECORD_DELETE_FAILED: 'ลบบันทึกธนาคารไม่สำเร็จ',
    ACCOUNT_LOCK: 'บัญชีถูกล็อค โปรดติดต่อฝ่ายบริการลูกค้า',
    BANK_ACCOUNT_EXISTS: 'มีบัญชีธนาคารแล้ว! กรุณาเพิ่มใหม่',

    DEVICE_CHANGE_LIMIT_REACHED: 'เชื่อมโยงอุปกรณ์ครบจำนวนที่กำหนดแล้ว',

    // Wallet Transaction
    TOPUP_ADD_FAILED: 'บันทึกเติมเงินไม่สำเร็จ',
    TOPUP_APPROVE_FAILED: 'อนุมัติเติมเงินไม่สำเร็จ',
    WITHDRAW_ADD_FAILED: 'เพิ่มบันทึกถอนเงินไม่สำเร็จ',
    WITHDRAW_APPROVE_FAILED: 'อนุมัติถอนเงินไม่สำเร็จ',
    TRANSFER_SEND_ADD_FAILED: 'เพิ่มบันทึกการโอนไม่สำเร็จ (ผู้ส่ง)',
    TRANSFER_REC_ADD_FAILED: 'เพิ่มบันทึกโอนเงินไม่สำเร็จ (ผู้รับ)',
    TRANSFER_RECINFO_FAILED: 'เพิ่มข้อมูลผู้รับไม่สำเร็จ',
    TRANSFER_CURRENCY_FAILED: 'โอนเงินไม่สำเร็จ (สกุลเงินไม่ถูกต้อง)',
    UPLOAD_PROCESS_FAILED: 'อัปโหลดใบเสร็จไม่สำเร็จ',
    TICKET_SAVE_FAILED: 'บันทึกรายการไม่สำเร็จ',
    OTP_VERIFY_FAILED: 'รหัส OTP ไม่ถูกต้อง',

    BALANCE_INSUFFICIENT: 'ยอดเงินคงเหลือไม่เพียงพอ',
    NO_AVAIL_DIGIT: 'ไม่สามารถเติมเงินได้ในขณะนี้ โปรดลองใหม่อีกครั้งในภายหลัง',

    // File
    UPLOAD_ERROR: 'อัปโหลดใบเสร็จไม่สำเร็จ',
    FILE_ERROR: 'ชนิดไฟล์ไม่ถูกต้อง (.png หรือ .jpg)',

    // Backend - Withdraw
    WITHDRAW_PROGRESS: 'ระบบกำลังดำเนินการถอนเงิน',

    // Promo
    PROMO_NO: 'ไม่มีโปรโมชั่น',
    PROMO_CLAIMED: 'ใช้สิทธิ์รับโปรโมชั่นเรียบร้อยแล้ว',
    PROMO_NO_MIN: 'ไม่มีโปรโมชั่น เติมเงินขั้นต่ำ xxx',
    PROMO_BANK_VERIFY: 'อยู่ในระหว่างดำเนินการยืนยันบัญชีธนาคาร',
    PROMO_EXPIRED: 'อั่งเปาหมดอายุแล้ว',
    PROMO_ANGPAO_ERR:
      'ไม่สามารถเพิ่มอั่งเปา หรือ ใช้สิทธิ์รับอั่งเปาเรียบร้อยแล้ว',
    PROMO_ANGPAO_NEGATIVE: 'กรุณาลองอีกครั้ง',

    // Mission
    MISSION_MISS: 'ระบบขัดข้อง', //  'Missing mission',
    MISSIONID_MISS: 'ระบบขัดข้อง', //  'Missing mission ID',
    REPEATABLE_MISS: 'ระบบขัดข้อง', //  'Missing repeatable',
    TARGET_MISS: 'ระบบขัดข้อง', //  'Missing target',
    REWARD_MISS: 'ระบบขัดข้อง', //  'Missing reward',

    MISSION_LIST_FAILED: 'ไม่สามารถรับรายการภารกิจได้',
    RECORD_LIST_FAILED: 'ไม่สามารถรับรายงานการบันทึกได้',
    MISSION_NOT_FOUND: 'ไม่พบภารกิจ',
    MISSION_CLAIM_FAILED: 'ไม่สามารถอ้างสิทธิ์ภารกิจได้',
    MISSION_CLAIMED: 'อ้างสิทธิ์ภารกิจแล้ว',
    MISSION_ADD_COMP_FAILED: 'ไม่สามารถเพิ่ม / สำเร็จภารกิจได้',
    MISSION_ADDED_COMPED: 'เพิ่มภารกิจแล้ว / เสร็จสิ้น',
    MISSION_CHECK_FAILED: 'ไม่สามารถตรวจสอบภารกิจได้',
    MISSION_ARCHIVE_FAILED: 'ไม่สามารถเก็บภารกิจได้',

    //#endregion

    //#region #3

    // SMS, Game
    SMS_INSUFFICIENT: 'รหัส OTP ไม่ถูกต้อง',
    SMS_SEND_FAILED: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
    OTP_SEND_FAILED: 'ส่งรหัส OTP ไม่สำเร็จ',
    OTP_NOT_EXIST: 'ไม่พบรหัส OTP',
    OTP_VERIFIED: 'รหัส OTP ถูกยืนยันแล้ว',

    // Game Integration
    GAME_UNKNOWN_ERROR: 'ไม่ทราบข้อมูลข้อผิดพลาดของเกม',
    GAME_USERID_NOT_EXIST: 'ไม่พบยูสเซอร์เนมนี้',
    GAME_MAINTENANCE: 'ระบบกำลังปรับปรุงเกมนี้',
    GAME_INVALID: 'ไม่พบเกมนี้',
    GAME_API_CATCH: 'ข้อผิดพลาด Api Catch',
    GAME_NETWORK_INTERRUPT: 'ระบบถูกรบกวน ไม่สามารถออกจากเกมได้',

    // Game API
    GAME_LOGIN_ERROR: 'ข้อผิดพลาดในการเข้าสู่ระบบเกม',
    GAME_CREATE_MEMBER_ERROR: 'ข้อผิดพลาดในการสร้างบัญชีสมาชิก',
    GAME_BALANCE_ERROR: 'ข้อผิดพลาดยอดเงินคงเหลือของเกม',
    GAME_DEPOSIT_ERROR: 'ข้อผิดพลาดในการฝากเงิน',
    GAME_WITHDRAW_ERROR: 'ข้อผิดพลาดในการถอนเงิน',
    GAME_BETLIMIT_ERROR: 'ข้อผิดพลาดในยอดจำกัดการเดิมพัน',
    GAME_DOWNLOAD_TICKET_ERROR: 'ข้อผิดพลาดในการดาวน์โหลดเกม',
    GAME_AMOUNT_ZERO: 'ข้อผิดพลาดจำนวนเกมเป็นศูนย์',
    GAME_PLAY_ERROR: 'ข้อผิดพลาดในการเริ่มเกม',
    GAME_EXIT_ERROR: 'ข้อผิดพลาดในการออกจากเกม',
    GAME_API_ERROR: 'ข้อผิดพลาด API',
    //#endregion
  },
  sideMenu: {
    notification: 'การแจ้งเตือน',
    myProfile: 'โปรไฟล์ของฉัน',
    myBank: 'ธนาคารของฉัน',
    statement: 'รายงานบัญชี',
    allGames: 'เกมทั้งหมด',
    gamesConfig: 'การกำหนดค่าเกม',
    gamesLog: 'บันทึกเกม',
    promotionAndReward: 'รางวัล & โปรโมชั่น',
    vip: 'VIP',
    affiliate: 'พันธมิตร',
    applyPremiumAgent: 'สมัครตัวแทนพรีเมี่ยม',
    contactUs: 'ติดต่อเรา',
    downloadApp: 'ดาวน์โหลดแอป',
    feedback: 'ข้อเสนอแนะ',
    theme: 'ธีม',
    logout: 'ออกจากระบบ',
    openInDefaultBrowser: 'เปิดในเบราว์เซอร์เริ่มต้น',
    slot: 'เกมสล็อต',
    sport: 'กีฬา',
    live: 'คาสิโนสด',
    fish: 'เกมยิงปลา',
  },
  statement: {
    transaction: 'ธุรกรรม',
    promotion: 'โปรโมชั่น',
    affiliate: 'พันธมิตร',
    games: 'เกมส์',
  },

  intropage: {
    or: 'หรือ',
    hotGames: 'เกมฮ็อต PG',
    featuredGames: `${clientInfo.appname} เเนะนำ`,
    newGames: 'เกมใหม่',
    allGames: 'เกมทั้งหมด',

    registerBtn: 'ลงทะเบียน',
    loginBtn: 'เข้าสู่ระบบ',
    downloadAppBtn: 'ดาวน์โหลดแอป',
    moreBtn: 'เพิ่มเติม',
    moreInfoBtn: 'เพิ่มเติม',
    inviteBtn: 'เชิญชวน',
    viewAllBtn: 'ดูทั้งหมด',
    joinNowBtn: 'เข้าร่วมเลย',
    playBtn: 'เล่น',
    goBtn: 'ไป',

    promotionHighlight: 'โปรโมชั่นไฮไลท์',
    promotionHighlightItem1Title: 'วงล้อนำโชค',
    promotionHighlightItem1SubTitle: 'สูงสุด 100% ฟรี!!!',
    promotionHighlightItem1Desc: 'เทิร์นโอเวอร์ x1 เท่านั้น',
    promotionHighlightItem1Info:
      'แค่เติมเงิน 100 บาทขึ้นไปตอนนี้ ก็สามารถรับสิทธิ์หมุนวงล้อนำโชคเพื่อ ลุ้นรับเครดิตฟรีสูงสุดถึง 100%!!!',
    promotionHighlightItem2Title: 'เงินคืนไม่จำกัด',
    promotionHighlightItem2SubTitle: 'แจกคืนให้ทุกวัน!!!',
    promotionHighlightItem2Desc: '0.3% จากยอดเทิร์น',
    promotionHighlightItem2Info:
      'รับเงินคืนพิเศษ 0.3% แบบไม่จำกัดทุกวันตลอดชีพ ยิ่งคุณเล่นมากเท่าไหร่ ก็ยิ่งได้คืนมากขึ้นเท่านั้น!!!',
    promotionHighlightItem3Title: 'โบนัสเงินคืน 5%',
    promotionHighlightItem3SubTitle: 'จากยอดเสียของคุณ',
    promotionHighlightItem3Desc: 'พิเศษสำหรับเกมสล็อต',
    promotionHighlightItem3Info:
      'รับเงินคืนยอดเสีย 5% ทุกวัน! ยิ่งเล่นมากเท่าไหร่ ยิ่งรับคืนมากเท่านั้น รีบเติมเงินเข้าสู่ระบบตอนนี้ และเล่นเกมสล็อตที่คุณชอบได้เลย!!!',
    promotionHighlightItem4Title: 'รับฟรี 50 บาท',
    promotionHighlightItem4SubTitle: `บนเว็บไซต์ ${clientInfo.appname}!!!`,
    promotionHighlightItem4Desc: 'จากทุกการเชิญชวนที่สำเร็จ',
    promotionHighlightItem4Info:
      'รางวัลเครดิตฟรี 50 บาท จะถูกโอนเข้าสู่กระเป๋าหลักของคุณ เมื่อคุณเชิญชวนเพื่อนเข้าร่วม กับเว็บของเราได้สำเร็จ!!!',

    affiliate: 'พันธมิตร',
    affiliateInfo1Title: '1 แชร์ รับ 50 บาท',
    affiliateInfo1Desc: 'เมื่อดาวน์ไลน์ฝากเงิน',
    affiliateInfo2Title: 'ค่าคอมมิชชั่นพันธมิตร 14%',
    affiliateInfo2Desc: 'T1: 10% | T2: 3% | T3: 1%',
    affiliateReport: 'รายงานพันธมิตร',
    premiumAffiliate: 'พันธมิตรพรีเมี่ยม',
    affiliateDownline: 'ดาวน์ไลน์พันธมิตร',

    brandClub: `คลับ ${clientInfo.appname}`,
    movieClub: 'มูฟวี่คลับ',
    movieClubDesc:
      'ไม่มีวันเบื่อแน่นอน\nด้วยภาพยนตร์นับพันเรื่อง\nดูเพลินเมื่ออยากพักจากเกม\nหนังใหม่อัปเดตต่อเนื่องทุกวัน',
    movieClubInfo1Title: 'ดูหนังฟรี 4,000++',
    movieClubInfo2Title: 'หนังผู้ใหญ่ 4,000++',

    downloadAppDesc: 'เพลิดเพลินสิทธิพิเศษมากยิ่งขึ้นด้วยการดาวน์โหลดแอปของเรา',

    aboutUsInfo: `${clientInfo.appname} มุ่งมั่นที่จะมอบประสบการณ์จากเกมลิขสิทธิ์แท้โดยเฉพาะที่ได้รับการรับรองจากสถาบันที่เชื่อถือได้และได้รับการยอมรับมากที่สุดในอุตสาหกรรม เพื่อให้มั่นใจว่าผู้เล่นทุกคนจะได้รับความอุ่นใจจากการเล่นเกมที่มีความยุดติธรรม & ปลอดภัยที่สุด`,
    licensesCertification: 'ใบอนุญาตและใบรับรอง',
    licensesCertificationInfo: 'การันตีเกมลิขสิทธิ์แท้',
    joinOurCommunity: 'เข้าร่วมชุมชนของเรา',
    contactUs: 'ติดต่อเรา',
    supportedPayment: 'การชำระเงินที่รองรับ',
    paymentSupported: 'รองรับการชำระเงิน',

    wallet: 'กระเป๋าเงิน',
    deposit: 'เติมเงิน',
    withdrawal: 'ถอนเงิน',
    transcationRecords: 'บันทึกธุรกรรม',
    gameLog: 'บันทึกเกม',
    myProfile: 'โปร์ไฟล์ของฉัน',
    myBank: 'ธนาคารของฉัน',
    promotion: 'โปรโมชั่น',
    bonus: 'โบนัส',
    mission: 'ภารกิจ',
    rebate: 'เงินคืน',

    vip: 'VIP',
    vipClub: 'คลับ VIP',
    vipBenefits: 'สิทธิประโยชน์ VIP',
    vipTier: 'ระดับ VIP',
    vipConcierge: 'บริการลูกค้า VIP',

    games: 'เกมส์',
    gamesID: 'ไอดีเกม',
    slots: 'สล็อต',
    casino: 'คาสิโน',
    fish: 'ยิงปลา',
    sport: 'กีฬา',
    lotto: 'หวย',
    appGames: 'แอปเกม',

    sport: 'กีฬา',
    slotGames: 'เกมสล็อต',
    sportBook: 'กีฬา',
    liveCasino: 'คาสิโนสด',
    fishGames: 'เกมยิงปลา',
    gamesConfig: 'ตั้งค่าเกม',

    aboutUs: 'เกี่ยวกับเรา',

    copyright: `Copyright ${clientInfo.appname} @ ${clientInfo.currYear} All Rights Reserved`,
  },
  login: {
    rmbPw: 'บันทึกรหัสผ่าน',
    forgotPw: 'ลืมรหัสผ่าน',
  },
  button: {
    continue: 'ถัดไป',
    resendOtp: 'ส่งอีกครั้ง',
  },
  registerBank: {
    title: 'ลงทะเบียนบัญชี',
  },
  setPassword: {
    title: 'ตั้งรหัสผ่าน',
  },
  enterPassword: {
    title: 'กรอกรหัสผ่าน',
  },
  trueWallet: {
    transferFrom: 'โอนจาก:',
    registerTitle: 'ลงทะเบียน True Wallet',
    registerDesc:
      'กรุณาลงทะเบียนบัญชีธนาคารของคุณเพื่อเปิดใช้งานฟังก์ชั่นการฝากและถอนเงิน',
    enterDepositAmount: 'ระบุจำนวนเงินฝาก (บาท)',
    minDeposit: 'ฝากขั้นต่ำ 10 บาท',
    minDepositAmt: '10 บาท',
    addedAccMsg: 'เพิ่มบัญชีสำเร็จ',
    addAcc: 'เพิ่มบัญชี',
    editAcc: 'แก้ไข',
  },
  loginProhibition: {
    title: 'ระบบกันตัวเอง',
    desc: 'เลือกช่วงเวลาเพื่อป้องกันไม่ให้คุณกลับเข้าสู่แอปของเรา',
    successMsg: 'ส่งคำขอสำเร็จ',
  },
  dateUnit: {
    days_one: '{{dateUnit}} วัน',
    days_other: '{{dateUnit}} วัน',
    weeks_one: '{{dateUnit}} สัปดาห์',
    weeks_other: '{{dateUnit}} สัปดาห์',
    months_one: '{{dateUnit}} เดือน',
    months_other: '{{dateUnit}} เดือน',
  },
  security: {
    title: 'การยืนยันตัวตนแบบ 2 ขั้น',
    googleAuthOpt: 'การยืนยันตัวตนด้วย Google 2FA',
    googleAuth: {
      setupTitle: 'ตั้งค่า Google Authenticator',
      setupDesc_1: 'โปรดติดตั้งแอป Google Authenticator บนโทรศัพท์ของคุณ',
      setupDesc_2: 'โปรดสแกนรหัส QR ใน Google Authenticator',
      setupDesc_3: '(Google Authenticator)',
      unbindTitle: 'ยกเลิกการผูกบัญชีกับ Google Authenticator',
    },
  },
};
