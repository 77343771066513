import React, {
  useEffect,
  lazy,
  Suspense,
  useLayoutEffect,
  useMemo,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  // useNavigate,
  useSearchParams,
} from 'react-router-dom';

import '../../../App.scss';
import {isMobile, logIn, token} from '../../../common/redux/userSlice';

//wallet
import {GetRoute} from '../MainIndex';
import {GetSign_In} from '../../../resources/image/Image';
import api from '../../../common/api';

import variables from '../../../resources/theme/Common.scss';
import {
  addReferral,
  checkIsFromGoogleSEO,
  RouteIsExisted,
  EXCLUDE_HEADERFOOTER_PAGE,
  ShowLoginDialog,
  clearReferral,
  NavigateToLogin,
  useNav,
  RouteIsMatch,
} from '../../../common/utils/helper';
import Loading from '../../../common/components/root/loading_spinner/Loading';
import {
  setTheme,
  setShowHeaderFooter,
} from '../../../common/redux/stylingSlice';
import ScrollToTop from '../../../common/components/ScrollToTop';
import SuspenseLoading from '../../../common/components/root/loading_spinner/SuspenseLoading';
import './MainIndex.scss';
import ModalStack from '../../../common/components/modal/ModalStack';
import PublicRoutes from '../PublicRoutes';
import {useModal} from '../../../common/context/ModalContext';
import AuthRoutes from '../AuthRoutes';
import MsgModalStack from '../../../common/components/modal/MsgModalStack';
import {store} from '../../../common/redux/Store';

const scss = {
  mainBg: variables.mainBg,
};

export default function Navigation() {
  const Sign_In = GetSign_In();
  const dispatch = useDispatch();
  const signIn = sessionStorage.getItem('logIn');
  const isSignIn = JSON.parse(signIn);
  const useModalContext = useModal();
  const {theme} = useSelector((state) => state.styling); //if put this when switch theme will refresh the whole app
  const refercode = useSearchParams()[0].get('rc');
  const {iframeContent} = useSelector((state) => state.styling);
  const subdomainRc = window.location.origin;

  const setReferralCode = (rc) => {
    clearReferral();
    addReferral(rc);
    checkIsFromGoogleSEO();
  }
  //referral
  if (refercode) {
    setReferralCode(refercode);
  }else if(subdomainRc) {
    const referralArray = JSON.parse(localStorage.getItem('referral')) || [];
    const referral = referralArray[0]?.rc;
    const domainArr = subdomainRc.split('.');
    const pattern = /(https:\/\/|http:\/\/)/g;
    const rc = domainArr[0].replace(pattern,'');
    if(referral) {
      setReferralCode(referral);
    } else if (domainArr.length === 3 && rc !== 'www'){
      setReferralCode(rc);
    }
  }
  //theme
  useEffect(() => {
    //just for testing, when api done, do it in appinit
    dispatch(setTheme(theme));
  }, []);

  // screen size
  useEffect(() => {
    sessionStorage.removeItem('anim');
    function handleResize() {
      if (window.innerWidth < 400) {
        dispatch(isMobile(true));
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getAppInit();
    // ShowLoginDialog(useModalContext, null, true);

    // if (!RouteIsMatch('/lineLogin', location.pathname)) {
    //   NavigateToLogin(navigate, null);
    // }

    // window.addEventListener('focus', onFocus);
    window.addEventListener('popstate', removeLoading);
    // window.addEventListener('focus', getAppInit);
    // window.addEventListener('blur', onBlur);
    return () => {
      // window.removeEventListener('focus', onFocus);
      window.removeEventListener('popstate', removeLoading);
      // window.removeEventListener('focus', getAppInit);
      // window.removeEventListener('blur', onBlur);
    };
  }, []);

  useLayoutEffect(() => {
    function auth() {
      if (isSignIn) {
        const isToken = JSON.parse(sessionStorage.getItem('token'));
        dispatch(logIn(isSignIn));
        dispatch(token(isToken));
      }
    }

    auth();
  }, [signIn]);

  // const onFocus = () => {
  //   const verify = localStorage.getItem('verified');
  //   if (verify === null) {
  //     window.location.reload();
  //   }
  // };

  const removeLoading = () => {
    Loading.hide();
  };

  // const onBlur = () => {
  //   // console.log('=>* Tab is blurred');
  // };

  function getAppInit() {
    const path = window.location.pathname;
    const anim = JSON.parse(sessionStorage.getItem('anim'));

    if (
      path === '/resetPin' ||
      path === '/signUp' ||
      path === '/setPin' ||
      path === '/playgame' ||
      anim
    )
      return;
    api.AppInit({});
  }

  return (
    <div className={`container ${iframeContent ? 'iframe-container' : ''}`}>
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route element={<Auth />}>
          {AuthRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route element={<Public isAuth={isSignIn} to="/" />}>
          {PublicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          <Route path="*" exact element={<Navigate to="/" replace />} />
        </Route>
        <Route element={<Protect isAuth={isSignIn} />}>
          {/* <Route
            path={'/topup'}
            element={
              isSignIn ? (
                Object.keys(isSignIn.bank).length === 0 ? (
                  <Navigate to="/" replace />
                ) : (
                  <Topup title={trans('general.title.topUp')} />
                )
              ) : null
            }
          /> */}

          {GetRoute()}
        </Route>
      </Routes>
      <ModalStack />
      <MsgModalStack />
    </div>
  );
}

/**
 * @description  [Outlet]: https://reactrouter.com/docs/en/v6/components/outlet
 */

const Protect = ({isAuth}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;
  const {search} = useLocation();

  useLayoutEffect(() => {
    if (RouteIsExisted(EXCLUDE_HEADERFOOTER_PAGE, currentRoute)) {
      dispatch(setShowHeaderFooter(false));
    } else {
      dispatch(setShowHeaderFooter(true));
    }
  }, [location.pathname]);

  if (isAuth === undefined) return null; // or loading spinner, etc...

  return isAuth ? (
    <Suspense fallback={<SuspenseLoading show={true} />}>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to={`/signIn/${search}`} state={{from: location}} replace />
  );
};

const Public = ({isAuth, to}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;

  useLayoutEffect(() => {
    if (RouteIsExisted(EXCLUDE_HEADERFOOTER_PAGE, currentRoute)) {
      dispatch(setShowHeaderFooter(false));
    } else {
      dispatch(setShowHeaderFooter(true));
    }
  }, [location.pathname]);

  if (isAuth === undefined) return null; // or loading spinner, etc...

  return (
    <Suspense fallback={<SuspenseLoading show={true} />}>
      <Outlet />
    </Suspense>
  );
};

const Auth = () => {
  const {isLogin, profile} = useSelector((state) => state.user);

  const navigate = useNav();
  useEffect(() => {
    //use store instead useSelector because i dont want it re-render.
    const currentIsLogic = store.getState().user.isLogin;
    if (currentIsLogic)
      navigate(
        `/${profile.referCode == null ? '' : `?rc=${profile.referCode}`}`,
        {replace: true},
      );
  }, []);

  return isLogin ? null : (
    <Suspense fallback={<SuspenseLoading show={true} />}>
      <Outlet />
    </Suspense>
  );
};
