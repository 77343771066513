import React from 'react';
import {GetHome} from '../../../resources/image/Image';
import {clientInfo} from '../../../resources';
import './NavBrandLogo.scss';

const NavBrandLogo = ({textClassName, onClick}) => {
  const HomeIcon = GetHome();
  return (
    <div className="nav-brand-container" onClick={onClick}>
      <img
        src={HomeIcon.smallLogo}
        className="nav-brand-icon"
        alt="nav-brand-icon"
      />
      {clientInfo.headerAppName ? (
        <div className={`${textClassName && textClassName} nav-brand-name`}>
          {clientInfo.headerAppName}
        </div>
      ) : null}
    </div>
  );
};

export default NavBrandLogo;
