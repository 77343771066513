import React from 'react';
import PropTypes from 'prop-types';
import './LicenseCertificationComponent.scss';
import scssVariales from '../../../resources/theme/Common.scss';
import {GetCommon} from '../../../resources/image/Image';



LicenseCertificationComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

LicenseCertificationComponent.defaultProps = {
  title: '',
  text: '',

};

export default function LicenseCertificationComponent({
  title,
  text,
}) {
  const Common = GetCommon();
  return (
    <div className="license-certification-container">
            <div className="license-certification-title">{title}</div>
            <div style={{height:'20px'}}> </div>
            <div className="license-certification-icons"> 
            <img className='license-certification-gc-icon' src={Common.GC} alt={'gc-icon'}/>
            <div className='license-certification-col'>
                <div className="license-certification-authentic-games-subtitle">{text}</div>
                <img className='license-certification-itl-icon' src={Common.iTL} alt={'itl-icon'} style={{height:'42px', paddingRight: '7px'}}/>
                <img className='license-certification-gli-icon' src={Common.GLI} alt={'gli-icon'} style={{height:'42px', paddingRight: '7px'}}/>
                <img className='license-certification-iovBMM-icon' src={Common.iovationBMMTestLabs} alt={'iovBMM-icon'} style={{height:'39px'}}/>
            </div>
            </div> 
    </div>
  );
}
