import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './PinTextField.scss';
import {useTranslation} from 'react-i18next';

const PinTextField = forwardRef(
  (
    {
      name,
      value,
      isFormChild, //for formComponent to check formChild
      length,
      isRequired,
      validation,
      formRef,
      scrollRef,
      frameRef,
      groupRef,
      onChange,
    },
    ref,
  ) => {
    const {t: trans} = useTranslation();
    const childComponents = new Array(length).fill(null);
    const inputRefs = useRef([]);
    const disableHandleFocus = useRef(false);
    const disableOnChange = useRef(false);
    const [values, setValues] = useState([]); // Initialize with empty values

    const baseContainerRef = useRef(null);
    const isRealTimeValidation = useRef(false);
    const [errMsg, setErrMsg] = useState('');
    const isExternalValue = useRef(false); //To disable OnChange callback while receiving external value

    React.useImperativeHandle(ref, () => ({
      ValidateValue,
      setErrMsg: setErrMsg,
      ScrollToTextField: () => {
        if (formRef) {
          if (scrollRef) {
            //if not body scroll, then can direct use scrollIntoView
            baseContainerRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          } else {
            //default app all is using body as scroll
            const scrollContainerRect = document.body.getBoundingClientRect();
            const elementRect =
              baseContainerRef.current.getBoundingClientRect();

            // Calculate the position relative to the scroll container
            const tfTop = elementRect.top - scrollContainerRect.top;
            let frameRect;
            if (frameRef) {
              frameRect = frameRef.current.getBoundingClientRect();
            } else {
              //if no frameRef default will use formRef as the frame
              frameRect = formRef?.current?.getBoundingClientRect();
            }
            const frameTop = frameRect.top - scrollContainerRect?.top;

            window.scrollTo({
              top: tfTop - frameTop,
              behavior: 'smooth',
            });
          }
        }
      },
    }));

    useEffect(() => {
      setValues(value.split(''));
      isExternalValue.current = true;
    }, [value]);

    useEffect(() => {
      if (groupRef?.current) {
        //groupTextField component
        if (isRealTimeValidation.current) {
          groupRef.current.ValidateValue({scrollTo: false});
        }
      } else {
        if (isRealTimeValidation.current) {
          ValidateValue();
        }
      }
      if (onChange && !isExternalValue.current) {
        onChange({name: name, value: values.join('')});
      }
      isExternalValue.current = false;
    }, [values]);

    async function ValidateValue() {
      isRealTimeValidation.current = true;
      const fullValue = values.join('');
      if (isRequired && fullValue.trim().length != length) {
        setErrMsg('general.errMsg.fieldRequired');
        return false;
      }
      if (validation) {
        const newErrMsg = await validation(fullValue);
        if (newErrMsg) {
          //if async validation will need this checking
          //cannot use value, because it is not the latest state
          const currentValue = inputRefs.current
            .map((ref) => ref.value)
            .join('');
          if (!(isRequired && currentValue.trim().length != length)) {
            setErrMsg(newErrMsg);
          }
          return false;
        }
        setErrMsg('');
        return true;
      }
      setErrMsg('');
      return true;
    }

    const handleChange = (e, index) => {
      if (disableOnChange.current) {
        disableOnChange.current = false;
        return;
      }
      const differences =
        e.target.value.length - (values[index] ? values[index].length : 0);

      //mean user copy and paste
      if (differences > 1) {
        console.log(`e ${e.target.value}`);
        const inputValue = e.target.value.replace(/[^0-9]/g, '');
        const newValues = [...values];
        for (let i = 0; i < inputValue.length; i++) {
          if (index + i >= length) {
            break;
          }
          newValues[index + i] = inputValue[i];
        }
        setValues(newValues);

        disableHandleFocus.current = true;

        if (index + inputValue.length < length) {
          inputRefs.current[index + inputValue.length].focus();
        } else {
          inputRefs.current[length - 1].focus();
        }
      } else {
        const newInputValue = e.target.value.replace(/[^0-9]/g, '');

        const newValues = [...values];
        newValues[index] = newInputValue
          ? newInputValue[newInputValue.length - 1]
          : '';
        setValues(newValues);

        //because first and last digit wont auto move
        if (
          !(!newInputValue && index == 0) &&
          !(newInputValue && index == length - 1)
        ) {
          disableHandleFocus.current = true;
        }

        //mean if it enter digit only will focus to next input
        if (e.target.value == newInputValue) {
          if (newInputValue) {
            // Automatically move to the next input
            if (index < length - 1) {
              inputRefs.current[index + 1].focus();
            }
          } else {
            // Automatically move to the previous input
            backToPrevious(index);
          }
        }
      }
    };

    const handleFocus = (e) => {
      e.target.select();

      if (disableHandleFocus.current) {
        disableHandleFocus.current = false;
        return;
      }
      const allEmpty = values.every((value) => value == '');

      if (allEmpty) {
        inputRefs.current[0].focus();
      }
    };

    const handleOnKeyDown = (e, index) => {
      if (e.key === 'Enter') {
        const refValue = formRef?.current;
        if (refValue && refValue.onSubmit) {
          inputRefs.current[index].blur();
          refValue.onSubmit();
        }
      }
      if (
        (e.key === 'Delete' || e.key === 'Backspace') &&
        !values[index] &&
        index != 0
      ) {
        disableOnChange.current = true;
        backToPrevious(index);
      }
    };

    const backToPrevious = (index) => {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    };

    return (
      <div className="pinTextField-container" ref={baseContainerRef}>
        <div className="pinTextField-input-container">
          {childComponents.map((_, index) => (
            <PinItem
              key={index}
              value={values[index] ?? ''}
              inputRef={(ref) => {
                inputRefs.current[index] = ref;
              }}
              onChange={(e) => handleChange(e, index)}
              onFocus={(e) => {
                handleFocus(e);
              }}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              isError={errMsg != '' ? true : false}
            />
          ))}
        </div>
        {errMsg != '' ? (
          <div className="pinTextField-err-msg">{trans(errMsg)}</div>
        ) : null}
      </div>
    );
  },
);

const PinItem = ({inputRef, value, onChange, onFocus, onKeyDown, isError}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className={`pinItem-container ${
        isError ? 'pinItem-error-container' : ''
      } ${isFocused ? 'pinItem-focus-container' : ''}`}>
      <input
        className="pinItem-input"
        inputMode="numeric"
        ref={inputRef}
        value={value}
        onChange={onChange}
        onFocus={(e) => {
          setIsFocused(true);
          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          setIsFocused(false);
        }}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default PinTextField;
