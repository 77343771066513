import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {IsSpecialUser, useNav} from '../../common/utils/helper';
import {GetHome, GetCommon} from '../../resources/image/Image';
import {clientInfo} from '../../resources';
import MarqueeComponent from './Marquee';
import WalletDetail from '../wallet/WalletDetail';
import Footer from './Footer';
import {useDispatch, useSelector} from 'react-redux';
import {setDummy} from '../../common/redux/optionSlice';
import Dialog from '../../common/components/root/dialog/Dialog';
import './HeaderFooterLayout.scss';
import SideMenu from '../sideMenu/SideMenu';
import {openSideMenu} from '../../common/redux/sideMenuSlice';
import NavBrandLogo from '../../common/components/NavBrandLogo/NavBrandLogo';
import {useModal} from '../../common/context/ModalContext';
import {LANGUAGE_MODAL_KEY} from '../../common/utils/modalHelper';
import LanguageModal from '../../common/components/modal/LanguageModal';
import LanguageComponent from '../../common/components/language/LanguageComponent';
import DimmedComponent from '../../common/components/FocusComponent/DimmedComponent';

function HeaderFooterLayout({children}) {
  const {modalStack, pushModal, popModal} = useModal();
  const navigate = useNav();
  const HomeIcon = GetHome();
  const Common = GetCommon();
  const dispatch = useDispatch();
  const topSectionRef = useRef(null);
  const gameUrl = useRef();
  const {profile: user} = useSelector(({user}) => user);
  const [selectingLang, setSelectingLang] = useState(false);
  const showHeaderFooter = useSelector(({styling}) => styling.showHeaderFooter);
  const iframeContent = useSelector(({styling}) => styling.iframeContent);

  useEffect(() => {
    if (modalStack[modalStack.length - 1]?.key === LANGUAGE_MODAL_KEY) {
      setSelectingLang(true);
    } else {
      setSelectingLang(false);
    }
  }, [modalStack]);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (topSectionRef.current) {
        const newHeight = topSectionRef.current.getBoundingClientRect().height;
        const root = document.documentElement;
        root.style.setProperty('--top-header-height', `${newHeight}px`);
      }
    });

    observer.observe(topSectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, [topSectionRef]);

  return (
    <div id="container" className="home-container">
      {showHeaderFooter ? (
        <div className="game-top" ref={topSectionRef}>
          <div className="game-top-container">
            <div className="game-top-left-section-container">
              <img
                src={HomeIcon.menu}
                className="game-top-menu-img"
                alt="menu-icon"
                onClick={() => {
                  dispatch(openSideMenu(true));
                }}></img>
              <NavBrandLogo
                onClick={() => {
                  navigate('/');
                  if (user.isTester.toLowerCase() === 'x') {
                    function InputArea() {
                      return (
                        <textarea
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            padding: 20,
                            marginBottom: 20,
                          }}
                          ref={gameUrl}
                          rows={1}
                          placeholder="Typing ..."
                        />
                      );
                    }
                    Dialog.show({
                      show: true,
                      customize: InputArea(),
                      cancelBtn: true,
                      btnAct: () => {
                        dispatch(setDummy(gameUrl.current.value));
                      },
                    });
                    return;
                  }
                }}
              />
            </div>

            <div className="game-top-right-section-container">
              {IsSpecialUser() ? null : <WalletDetail />}
              {selectingLang ? (
                <img
                  src={Common.modalClose}
                  className="game-top-close-icon"
                  alt="modal-close-icon"
                  onClick={() => {
                    popModal();
                  }}
                />
              ) : (
                <LanguageComponent
                // onClick={() => {
                //   document.getElementById('more_slider').style.transform =
                //     'translateX(0%)';
                //   document.getElementById('more_slider').style.display =
                //     'block';
                //   document.body.style.overflow = 'hidden';
                //   document.body.style.touchAction = 'none';
                // }}
                />
              )}
            </div>
          </div>
          <div className="game-marquee-container">
            <MarqueeComponent />
          </div>
        </div>
      ) : null}
      <div
        className={`${showHeaderFooter ? 'layout-page-content' : ''} ${
          iframeContent ? 'iframe-layout-page-content' : ''
        }`}>
        <DimmedComponent />
        {children}
      </div>
      {showHeaderFooter ? <Footer></Footer> : null}
      <SideMenu></SideMenu>
    </div>
  );
}

export default HeaderFooterLayout;
